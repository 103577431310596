/** @format */
import {useState, useCallback, useEffect, useMemo} from 'react';

import {
  Box,
  Button,
  LinearProgress,
  capitalize,
  Typography,
  Alert,
  InputAdornment,
  Tab,
} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {clsx} from 'clsx';
import {gql, useQuery, useMutation} from '@apollo/client';
import {format, add} from 'date-fns';
import {ru} from 'date-fns/locale';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import TodayIcon from '@mui/icons-material/Today';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import DatePicker from 'react-datepicker';

import SidebarEdit from 'components/MediaplanSidebar/SidebarEdit';
import LegacyTextField from 'components/inputs/LegacyTextField';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import Chart from './components/Chart';
import {StyledBox, StyledInfoAlert, StyledTabs, Switch} from './styles';
import {classes, CONTROLS_PERIODS, YEAR_DAYS} from './constants';
import {
  ControlsPeriod,
  Period,
  PeriodRepeatEveryType,
  PeriodType,
} from './interfaces';
import {getDayOfWeekInGenitive} from './utils';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      currentCompany {
        id
      }
    }
  }
`;

const UPDATE_MEDIAPLANS = gql`
  mutation UpdateMediaplans(
    $id: ID!
    $new: [AccountsMediaplanCreateInput!]!
    $updated: [AccountsMediaplanUpdateInput!]!
    $removed: [AccountsMediaplanRemoveInput!]!
  ) {
    updateMediaplans(id: $id, new: $new, updated: $updated, removed: $removed)
  }
`;

const formatBudgetPlan = (value: string) => {
  const cleanValue = value.replace(/[^\d.,]/g, '');
  const number = parseFloat(cleanValue.replace(',', '.'));

  if (isNaN(number)) {
    return {
      formatted: '',
      value: 0
    };
  }

  return {
    formatted: number.toLocaleString('ru-RU', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }),
    value: number
  };
};

const Periods = () => {
  const {t} = useTranslation();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const [activeControlsPeriod, setActiveControlsPeriod] =
    useState<ControlsPeriod>('quarter');

  const [periods, setPeriods] = useState<Period[] | []>([]);
  const [activePeriodId, setActivePeriodId] = useState<number | null>(null);

  const {loading, error, data} = useQuery(GET_CURRENT_USER);

  const [updateMediaplans] = useMutation(UPDATE_MEDIAPLANS);

  const handlePeriodChange = (key: ControlsPeriod) => {
    setActiveControlsPeriod(key);
  };

  const handlePeriodSwipe = useCallback(
    (value: number) => {
      if (!startDate || !endDate) return;

      const newStartDate = new Date(startDate);
      const newEndDate = new Date(endDate);

      let monthsToAdd;
      switch (activeControlsPeriod) {
        case 'quarter':
          monthsToAdd = 3;
          break;
        case 'half-year':
          monthsToAdd = 6;
          break;
        case 'year':
          monthsToAdd = 12;
          break;
        default:
          monthsToAdd = 3;
      }

      // Сдвигаем даты на соответствующее количество месяцев
      newStartDate.setMonth(newStartDate.getMonth() + monthsToAdd * value);
      newEndDate.setMonth(newEndDate.getMonth() + monthsToAdd * value);

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    },
    [activeControlsPeriod, startDate, endDate],
  );

  const handleAddPeriod = () => {
    const addedPeriodId = Date.now();

    setPeriods([
      ...periods,
      {
        id: addedPeriodId,
        startDate: null,
        endDate: null,
        type: 'repeatable',
        repeatEvery: 'monthly',
        isLinkedWithPeriodStart: true,
        budgetPlan: null,
      },
    ]);

    setActivePeriodId(addedPeriodId);
  };

  const handleBudgetPlanChange = (updatedValue: number) => {
    setPeriods(
      periods.map((el: Period) => {
        if (el.id === activePeriodId) {
          return {...el, budgetPlan: updatedValue};
        }

        return el;
      }),
    );
  };
  const handleActiveTabClick = (id: number) => {
    const period = periods.find((el: Period) => el.id === id);

    if (period) {
      setActivePeriodId(period.id);
    }
  };

  const handleTabRemove = (event: React.MouseEvent, id: number) => {
    event.stopPropagation();

    const newPeriods = periods.filter((period: Period) => period.id !== id);

    if (activePeriodId === id) {
      if (newPeriods.length > 0) {
        setActivePeriodId(newPeriods[newPeriods.length - 1].id);
      } else {
        setActivePeriodId(null);
      }
    }

    setPeriods(newPeriods);
  };

  const handleChangePeriodType = (type: PeriodType) => {
    updateRequest({type});
  };

  const handleChangePeriodRepeatEvery = (
    repeatEvery: PeriodRepeatEveryType,
  ) => {
    updateRequest({repeatEvery: repeatEvery});
  };

  const updateRequest = useCallback(
    (payload: Partial<Period>) => {
      if (activePeriodId) {
        setPeriods(
          periods.map((el: Period) => {
            if (el.id === activePeriodId) {
              return {...el, ...payload};
            }

            return el;
          }),
        );
      }
    },
    [activePeriodId, periods],
  );

  const handleStartDateChange = (date: Date | null) => {
    if (!date) {
      updateRequest({
        startDate: null,
        endDate: null,
      });
      return;
    }

    const nextPeriod = periods
      .filter(p => p.id !== activePeriodId && p.startDate)
      .sort((a, b) => {
        if (!a.startDate || !b.startDate) return 0;
        return a.startDate.getTime() - b.startDate.getTime();
      })
      .find(p => p.startDate && p.startDate > date);

    const updatedPeriods = periods.map((el: Period) => {
      if (el.id === activePeriodId) {
        return {
          ...el,
          startDate: date,
          endDate: nextPeriod?.startDate
            ? add(nextPeriod.startDate, {days: -1})
            : add(date, {days: YEAR_DAYS}),
        };
      }

      if (
        el.startDate &&
        el.endDate &&
        date >= el.startDate &&
        date <= el.endDate
      ) {
        const newEndDate = add(date, {days: -1});

        return {
          ...el,
          endDate: newEndDate,
        };
      }

      return el;
    });

    setPeriods(updatedPeriods);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (!date) return;

    updateRequest({endDate: date});
  };

  const handleLinkedWithPeriodStartChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateRequest({isLinkedWithPeriodStart: event.target.checked});
  };

  const mapPeriods = (period: Period) => {
    const isEndDateLessThanStartDate =
      period.endDate &&
      period.startDate &&
      period.endDate <
        add(period.startDate, {
          days: period.repeatEvery === 'weekly' ? 7 : 30,
        });

    const isShouldShowEndDate =
      period.type === 'flight' ||
      (period.type === 'repeatable' && isEndDateLessThanStartDate);

    return (
      <Tab
        key={period.id}
        value={period.id}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              position: 'relative',
            }}
          >
            <Box>
              {period.startDate
                ? format(new Date(period.startDate), 'd MMMM', {
                    locale: ru,
                  })
                : <Trans>Новый период</Trans>}
              {period.endDate && isShouldShowEndDate
                ? ` - ${format(new Date(period.endDate), 'd MMMM', {
                    locale: ru,
                  })}`
                : ''}
            </Box>
            <CloseIcon
              sx={{
                fontSize: 20,
                color: '#8B8B8B',
                opacity: 0.7,
                transition: 'opacity 0.2s',
                cursor: 'pointer',
                '&:hover': {
                  opacity: 1,
                },
              }}
              onClick={e => handleTabRemove(e, period.id)}
            />
          </Box>
        }
      />
    );
  };

  useEffect(() => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    let lastDay;

    switch (activeControlsPeriod) {
      case 'quarter':
        lastDay = new Date(now.getFullYear(), now.getMonth() + 3, 0);
        break;
      case 'half-year':
        lastDay = new Date(now.getFullYear(), now.getMonth() + 6, 0);
        break;
      case 'year':
        lastDay = new Date(now.getFullYear(), now.getMonth() + 12, 0);
        break;
      default:
        lastDay = new Date(now.getFullYear(), now.getMonth() + 3, 0);
    }

    setStartDate(firstDayOfMonth);
    setEndDate(lastDay);
  }, [activeControlsPeriod]);

  const activePeriod = useMemo(() => {
    return periods.find((el: Period) => el.id === activePeriodId);
  }, [periods, activePeriodId]);

  const activePeriodBudgetPlan = useMemo(() => {
    return activePeriod?.budgetPlan ? formatBudgetPlan(activePeriod.budgetPlan.toString()).formatted : '';
  }, [activePeriod]);

  const isSubmitAvailable = useMemo(() => {
    return (
      Boolean(activePeriod?.budgetPlan) &&
      activePeriod?.startDate &&
      activePeriod?.endDate &&
      activePeriod?.type &&
      activePeriod?.repeatEvery &&
      activePeriod?.isLinkedWithPeriodStart
    );
  }, [activePeriod]);

  const periodDescriptionMessage = useMemo(() => {
    const isTurned = activePeriod?.isLinkedWithPeriodStart;

    if (!activePeriod?.startDate) return '';

    if (activePeriod?.repeatEvery === 'weekly') {
      return isTurned
        ? t(
            'Следующий круг начнется с понедельника',
            'Следующий круг начнется с понедельника',
          )
        : `Следующий круг начнется с ${getDayOfWeekInGenitive(activePeriod.startDate)}`;
    }

    return isTurned
      ? t(
          'Следующий круг начнется с 1 числа',
          'Следующий круг начнется с 1 числа',
        )
      : t(
          'Следующий круг начнется с {{day}} числа',
          'Следующий круг начнется с {{day}} числа',
          {day: format(activePeriod?.startDate, 'd', {locale: ru})},
        );
  }, [
    activePeriod?.isLinkedWithPeriodStart,
    activePeriod?.repeatEvery,
    activePeriod?.startDate,
  ]);

  const handleSavePeriods = async () => {
    try {
      await updateMediaplans({
        variables: {
          id: data?.currentUser?.currentCompany?.id,
          new: periods.map(period => ({
            startOn: period.startDate
              ? format(period.startDate, 'yyyy-MM-dd')
              : null,
            endOn: period.endDate ? format(period.endDate, 'yyyy-MM-dd') : null,
            type: period.type.toUpperCase(),
            repeat: period.repeatEvery.toUpperCase(),
            startOnPeriodBegin: period.isLinkedWithPeriodStart,
            budget: period.budgetPlan,
          })),
          updated: [],
          removed: [],
        },
      });
    } catch (error) {
      console.error('Error saving periods:', error);
    }
  };

  const calculateDailyBudget = useCallback(() => {
    if (!activePeriod?.budgetPlan || !activePeriod.startDate) return null;

    let days = 30; // default for monthly
    if (activePeriod.repeatEvery === 'weekly') {
      days = 7;
    } else if (activePeriod.type === 'flight' && activePeriod.endDate) {
      const diffTime = Math.abs(
        activePeriod.endDate.getTime() - activePeriod.startDate.getTime(),
      );
      days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    return Math.round(activePeriod.budgetPlan / days);
  }, [activePeriod]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    handleActiveTabClick(newValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = formatBudgetPlan(e.target.value);

    handleBudgetPlanChange(value);
  };

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  return (
    <StyledBox>
      <SidebarEdit activeStep={'PERIODS'} enableAll />

      <Box>
        <Typography className={classes.heading}>
          <Trans>Периоды и планы</Trans>
        </Typography>
      </Box>

      <Box className={classes.controls}>
        <Box className={classes.controlsWrapper}>
          {CONTROLS_PERIODS.map(period => (
            <Button
              key={period.key}
              className={clsx(
                classes.controlButton,
                activeControlsPeriod === period.key &&
                  classes.controlButtonActive,
              )}
              onClick={() => handlePeriodChange(period.key)}
            >
              <Trans>{capitalize(period.name)}</Trans>
            </Button>
          ))}
        </Box>

        <Box className={classes.controlsWrapper}>
          <Button
            className={classes.swipeButton}
            onClick={() => handlePeriodSwipe(-1)}
          >
            <ArrowBackIosNewIcon />
          </Button>
          <Button
            className={classes.swipeButton}
            onClick={() => handlePeriodSwipe(1)}
          >
            <ArrowBackIosNewIcon />
          </Button>
        </Box>
      </Box>

      <Chart startDate={startDate} endDate={endDate} periods={periods} />

      {periods.length > 0 && (
        <StyledInfoAlert severity='info'>
          <Trans>
            Наведите курсор на элементы периода с небольшим количестввом дней для
            просмотра подробной информации
          </Trans>
        </StyledInfoAlert>
      )}

      <Box className={classes.periodsTabsWrapper}>
        {periods && periods.length > 0 && (
          <StyledTabs
            value={activePeriodId}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            {periods.map(mapPeriods)}
          </StyledTabs>
        )}
        <Button
          className={classes.addPeriodButton}
          onClick={handleAddPeriod}
          sx={{ml: 2}}
        >
          <AddIcon className={classes.addPeriodButtonIcon} />
          <Trans>Добавить период</Trans>
        </Button>
      </Box>

      {periods && periods.length > 0 && (
        <Box>
          <Box className={classes.periodForm}>
            <Button className={classes.dateButton}>
              <DatePicker
                locale={ru}
                selected={activePeriod?.startDate || null}
                onChange={handleStartDateChange}
                dateFormat="'С' d MMMM"
                minDate={new Date()}
                placeholderText={t('Выберите дату', 'Выберите дату')}
              />
              <InputAdornment position='end'>
                <TodayIcon className={classes.periodInputIcon} />
              </InputAdornment>
            </Button>

            <Box className={classes.periodButtonsBlock}>
              <Button
                className={clsx(
                  classes.periodButton,
                  activePeriod &&
                    activePeriod.type === 'repeatable' &&
                    classes.periodButtonActive,
                )}
                onClick={() => handleChangePeriodType('repeatable')}
              >
                <Trans>{t('Бессрочный', 'Бессрочный')}</Trans>
                <AllInclusiveIcon
                  className={clsx(
                    classes.periodButtonIcon,
                    classes.periodButtonIconBlue,
                  )}
                />
              </Button>
              <Button
                className={clsx(
                  classes.periodButton,
                  activePeriod &&
                    activePeriod.type === 'flight' &&
                    classes.periodButtonActive,
                )}
                onClick={() => handleChangePeriodType('flight')}
              >
                <Trans>{t('Срочный (флайт)', 'Срочный (флайт)')}</Trans>
                <MoveDownIcon
                  className={clsx(
                    classes.periodButtonIcon,
                    classes.periodButtonIconGreen,
                  )}
                />
              </Button>
            </Box>

            {activePeriod?.type === 'repeatable' && (
              <>
                <Box className={classes.periodButtonsBlock}>
                  <Button
                    className={clsx(
                      classes.periodButton,
                      activePeriod &&
                        activePeriod.repeatEvery === 'weekly' &&
                        classes.periodButtonActive,
                    )}
                    onClick={() => handleChangePeriodRepeatEvery('weekly')}
                  >
                    <Trans>{t('По неделям', 'По неделям')}</Trans>
                  </Button>
                  <Button
                    className={clsx(
                      classes.periodButton,
                      activePeriod &&
                        activePeriod.repeatEvery === 'monthly' &&
                        classes.periodButtonActive,
                    )}
                    onClick={() => handleChangePeriodRepeatEvery('monthly')}
                  >
                    <Trans>{t('По месяцам', 'По месяцам')}</Trans>
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box className={classes.periodSwitchWrapper}>
                    <Typography className={classes.periodSwitchDescription}>
                      <Trans>Привязать к началу месяца</Trans>
                    </Typography>
                    <Box sx={{ml: 4}}>
                      <Switch
                        checked={activePeriod?.isLinkedWithPeriodStart}
                        onChange={handleLinkedWithPeriodStartChange}
                        inputProps={{'aria-label': 'Привязать к началу месяца'}}
                      />
                    </Box>
                  </Box>
                  <Box sx={{mt: 2.5}}>
                    <Typography className={classes.periodSwitchDescription}>
                      <Trans>{periodDescriptionMessage}</Trans>
                    </Typography>
                  </Box>
                </Box>
              </>
            )}

            {activePeriod?.type === 'flight' && (
              <Button className={classes.dateButton}>
                <DatePicker
                  locale={ru}
                  selected={activePeriod?.endDate || null}
                  minDate={new Date()}
                  onChange={handleEndDateChange}
                  dateFormat="'По' d MMMM"
                  placeholderText={t('Выберите дату', 'Выберите дату')}
                />
                <InputAdornment position='end'>
                  <TodayIcon className={classes.periodInputIcon} />
                </InputAdornment>
              </Button>
            )}
          </Box>

          <Box sx={{mt: 16}}>
            <Typography className={classes.periodFormHeading}>
              <Trans>План бюджета на период</Trans>
            </Typography>

            <Box sx={{mt: 4}}>
              <Typography className={classes.periodFormDescription}>
                <Trans>Укажите плановое значение</Trans>
              </Typography>
            </Box>

            <Box sx={{mt: 8}} className={classes.budgetPlanWrapper}>
              <LegacyTextField
                className={classes.periodFormInput}
                type='text'
                value={activePeriodBudgetPlan}
                placeholder={t('План на период', 'План на период')}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CurrencyRubleIcon className={classes.periodInputIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              {!!activePeriod?.budgetPlan && !!activePeriod.startDate && (
                <Box className={classes.budgetPerDayWrapper}>
                  <Box className={classes.budgetPerDayTextWrapper}>
                    {activePeriod.type === 'repeatable' && (
                      <Typography className={classes.budgetPerDayText}>
                        <Trans>Примерно</Trans>
                      </Typography>
                    )}
                    <Typography className={classes.budgetPerDayText}>
                      {calculateDailyBudget()?.toLocaleString('ru-RU')} ₽{' '}
                      <Trans>в день</Trans>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          mt: 24,
          mb: 24,
        }}
      >
        <PrimaryMediumButton
          disabled={!isSubmitAvailable}
          onClick={handleSavePeriods}
        >
          <Trans>Сохранить и перейти к сенсорам</Trans>
        </PrimaryMediumButton>
      </Box>
    </StyledBox>
  );
};

export default Periods;
