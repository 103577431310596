/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'MEDIAPLAN_SIDEBAR';

export const classes = {
  pageContainer: `${PREFIX}-pageContainer`,
  pageHeader: `${PREFIX}-pageHeader`,
  pageContent: `${PREFIX}-pageContent`,
  sensorDescriptionWrapper: `${PREFIX}-sensorDescriptionWrapper`,
  yellowSensorsIcon: `${PREFIX}-yellowSensorsIcon`,
  redSensorsIcon: `${PREFIX}-redSensorsIcon`,
  graySensorsIcon: `${PREFIX}-graySensorsIcon`,
  sensorDescriptionIcon: `${PREFIX}-sensorDescriptionIcon`,
  sensorGroupIcon: `${PREFIX}-sensorGroupIcon`,
  unusedSensorsAmount: `${PREFIX}-unusedSensorsAmount`,
  saveAllButton: `${PREFIX}-saveAllButton`,
  saveAllButtonWrapper: `${PREFIX}-saveAllButtonWrapper`,
  sensorGroupsContainer: `${PREFIX}-sensorGroupsContainer`,
};

export const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.pageContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),

    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.spacing(20),
    },
  },
  [`& .${classes.pageHeader}`]: {
    fontWeight: 300,
    fontSize: 32,
    lineHeight: '40px',
  },
  [`& .${classes.pageContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  [`& .${classes.sensorDescriptionWrapper}`]: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  [`& .${classes.sensorDescriptionIcon}`]: {
    height: 16,
    width: 16,
  },
  [`& .${classes.sensorGroupIcon}`]: {
    height: 22,
    width: 22,
  },
  [`& .${classes.yellowSensorsIcon}`]: {
    transform: 'rotate(-90deg) scale(1.5) translate(-2px, 0)',
    color: theme.palette.figma_brown_1?.main,
  },
  [`& .${classes.redSensorsIcon}`]: {
    color: theme.palette.figma_red_1?.main,
  },
  [`& .${classes.graySensorsIcon}`]: {
    color: theme.palette.figma_gray_middle_dark?.main,
  },
  [`& .${classes.sensorGroupsContainer}`]: {
    paddingBlock: theme.spacing(8),
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  [`& .${classes.saveAllButton}`]: {
    background: theme.palette.success.main,
    borderRadius: 8,
    display: 'flex',
    height: '48px',
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: 'fit-content',
    userSelect: 'none',
    cursor: 'pointer',
  },
  [`& .${classes.saveAllButtonWrapper}`]: {
    position: 'fixed',
    bottom: 0,
    background: theme.palette.background?.default,
    width: '100vw',
    height: theme.spacing(20),
    paddingBlock: theme.spacing(4),
  },
  [`& .${classes.unusedSensorsAmount}`]: {
    display: 'flex',
    padding: theme.spacing(2.5),
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2.5),
    borderRadius: '8px',
    width: 'fit-content',
    background: '#202020',
    fontSize: '14px',
  },
}));
