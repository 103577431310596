/** @format */

import {ReactNode, useEffect, useReducer, useState} from 'react';

import {useMutation, useQuery} from '@apollo/client';
import {PlayArrow} from '@mui/icons-material';
import Circle from '@mui/icons-material/Circle';
import Square from '@mui/icons-material/Square';
import {Box, Typography} from '@mui/material';
import {clsx} from 'clsx';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {styled} from '@mui/material/styles';

import SidebarNew from 'components/MediaplanSidebar/SidebarNew';
import {useCompanyContext} from 'contexts/CompanyContext';
import theme from 'theme';

import TertiaryMediumButton from 'components/buttons/TertiaryMediumButton';

import {SensorGroup} from './components/SensorGroup';
import {SensorListModal} from './components/SensorListModal';
import {StyledBox, classes} from './styles';
import {GET_CURRENT_USER, UPDATE_COMPANY_SENSOR_GROUPS} from './queries';
import logoImg from './images/logo-small-dark-theme.svg';
import {DuplicateForm} from './components/DuplicateForm';

type Grouped = Record<keyof typeof GroupName, string[]>;
type Sensor = string;
interface ActionPayload {
  sensors: Sensor[];
  name?: string;
  grouped?: Grouped;
}
interface ActionProps {
  type: string;
  payload: ActionPayload;
}
export interface Group {
  name: string;
  icon: ReactNode;
  sensors: Sensor[];
}
interface StateProps {
  groups: Group[];
  available: Sensor[];
}
interface SensorWithName {
  name: string;
}

const GroupName = {
  red: 'Критичные',
  yellow: 'Обычные',
  gray: 'Некритичные',
} as const;

const initial: Group[] = [
  {
    name: GroupName.red,
    icon: (
      <Square
        className={clsx(classes.sensorGroupIcon, classes.redSensorsIcon)}
      />
    ),
    sensors: [],
  },
  {
    name: GroupName.yellow,
    icon: (
      <PlayArrow
        className={clsx(classes.sensorGroupIcon, classes.yellowSensorsIcon)}
      />
    ),
    sensors: [],
  },
  {
    name: GroupName.gray,
    icon: (
      <Circle
        className={clsx(classes.sensorGroupIcon, classes.graySensorsIcon)}
      />
    ),
    sensors: [],
  },
];

const StyledTertiaryMediumButton = styled(TertiaryMediumButton)(({theme}) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(3, 4, 3, 3),
}));

function stateReducer(
  {groups, available}: StateProps,
  {type, payload}: ActionProps,
): StateProps {
  if (type === 'init') {
    if (!payload.grouped) return {groups, available};

    const keys = Object.keys(GroupName) as Array<keyof typeof GroupName>;
    const updatedGroups = initial.map(initialGroup => {
      const group = keys.find(
        key => GroupName[key] === initialGroup.name,
      ) as keyof Grouped;
      const sensors = payload.grouped?.[group] || [];
      return {...initialGroup, sensors};
    });

    const used = Object.values(payload.grouped).flat();
    const updatedAvailableSensors = payload.sensors?.filter(
      name => !used.includes(name),
    );

    return {groups: updatedGroups, available: updatedAvailableSensors};
  }

  if (type === 'set') {
    const updatedGroups = groups.map((group: Group) => {
      if (group.name !== payload.name) return group;
      return {...group, sensors: payload.sensors};
    });
    const updatedAvailableSensors = available?.filter(
      name => !payload.sensors.includes(name),
    );

    return {groups: updatedGroups, available: updatedAvailableSensors};
  }

  if (type === 'remove') {
    const updatedGroups = groups.map((group: Group) => {
      if (group.name !== payload.name) return group;
      return {
        ...group,
        sensors: group.sensors.filter(
          sensor => !payload.sensors.includes(sensor),
        ),
      };
    });
    const updatedAvailableSensors = available?.concat(payload.sensors);

    return {groups: updatedGroups, available: updatedAvailableSensors};
  }

  return {groups, available};
}

const groupReducer = (acc: Grouped, {sensors, name}: Group): Grouped => {
  if (name === GroupName.red) {
    acc.red = acc.red.concat(sensors);
  } else if (name === GroupName.yellow) {
    acc.yellow = acc.yellow.concat(sensors);
  } else if (name === GroupName.gray) {
    acc.gray = acc.gray.concat(sensors);
  }
  return acc;
};

const Sensors = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {switchCompany} = useCompanyContext();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {
      id: companyId,
    },
  });
  const [updateCompanySensorGroups] = useMutation(UPDATE_COMPANY_SENSOR_GROUPS);
  const [{groups, available}, dispatch] = useReducer(stateReducer, {
    groups: [],
    available: [],
  });

  const [allSensors, setAllSensors] = useState<string[]>([]);
  useEffect(() => {
    const sensors = data?.allSensors.map((el: SensorWithName) => el.name);
    const grouped: Grouped = data?.currentUser.company.sensorGroups || {};
    dispatch({
      type: 'init',
      payload: {grouped, sensors},
    });
    setAllSensors(sensors);
  }, [data]);

  const [selectedGroupIcon, setSelectedGroupIcon] = useState<ReactNode>(
    initial[0].icon,
  );

  const [selectedGroupHeading, setSelectedGroupHeading] = useState(
    initial[0].name,
  );
  const [isDisplayingModal, setIsDisplayingModal] = useState(false);
  const [isDisplayingForm, setIsDisplayingForm] = useState(false);

  const sensorsUnused = t('plurals.sensors_unused', {count: available?.length});

  if (loading || error) return null;

  const handleModalOpen = (groupName: string) => {
    const selectedGroup = groups.find(el => el.name === groupName);
    if (!selectedGroup) return;
    setSelectedGroupIcon(selectedGroup.icon);
    setSelectedGroupHeading(selectedGroup.name);
    setIsDisplayingModal(true);
  };

  const handleModalSave = (selectedSensors: Set<string>) => {
    const preselected = groups.find(el => el.name === selectedGroupHeading);
    const payload = {
      name: selectedGroupHeading,
      sensors: Array.from(selectedSensors).concat(preselected?.sensors || []),
    };
    dispatch({type: 'set', payload});
  };

  const handleSensorsRemove = (name: string, id: string) => {
    const payload = {name, sensors: [id]};
    dispatch({type: 'remove', payload});
  };

  const handleModalClose = () => {
    setIsDisplayingModal(false);
  };
  const handleSubmitSensors = () => {
    const sensorGroups = groups.reduce(groupReducer, {
      red: [],
      yellow: [],
      gray: [],
    });
    updateCompanySensorGroups({
      variables: {id: companyId, sensorGroups},
    });
    switchCompany(data.currentUser.company);
    navigate('/home/main/status');
  };

  const handleFormOpen = () => {
    setIsDisplayingForm(true);
  };

  const handleFormClose = () => {
    setIsDisplayingForm(false);
  };

  return (
    <>
      <SidebarNew
        activeStep={'SENSORS'}
        enableAll
        onSaveAll={handleSubmitSensors}
      />

      <StyledBox>
        <Box className={classes.pageContainer}>
          <Typography className={classes.pageHeader}>
            <Trans>Сенсоры</Trans>
          </Typography>
          <Box className={classes.pageContent}>
            <Typography variant='body2'>
              <Trans>
                Чтобы сенсоры медиаплана работали, нужно настроить их важность
              </Trans>
            </Typography>
            <Box className={classes.sensorDescriptionWrapper}>
              <Square
                className={classes.sensorDescriptionIcon}
                htmlColor={theme.palette.figma_red_1?.main}
              />
              <Typography variant='body2'>
                <Trans>
                  Критичные сенсоры отправляют уведомления о срабатывании и
                  выводятся выше остальных
                </Trans>
              </Typography>
            </Box>
            <Box className={classes.sensorDescriptionWrapper}>
              <PlayArrow
                className={clsx(
                  classes.sensorDescriptionIcon,
                  classes.yellowSensorsIcon,
                )}
                htmlColor={theme.palette.figma_brown_1?.main}
              />
              <Typography variant='body2'>
                <Trans>Обычные сенсоры выводятся после критичных</Trans>
              </Typography>
            </Box>
            <Box className={classes.sensorDescriptionWrapper}>
              <Circle
                className={classes.sensorDescriptionIcon}
                htmlColor={theme.palette.figma_gray_middle_dark?.main}
              />
              <Typography variant='body2'>
                <Trans>
                  Некритичные сенсоры видны только на странице проекта
                </Trans>
              </Typography>
            </Box>
          </Box>

          <Box>
            <StyledTertiaryMediumButton onClick={handleFormOpen}>
              <img src={logoImg} alt='AdSensor' />
              <Typography variant='body2'>
                <Trans>Скопировать на другие проекты</Trans>
              </Typography>
            </StyledTertiaryMediumButton>
          </Box>

          <Box className={classes.sensorGroupsContainer}>
            {groups.map(({name, icon, sensors}) => (
              <SensorGroup
                key={name}
                name={name}
                icon={icon}
                sensors={sensors}
                onRemove={handleSensorsRemove}
                onAdd={handleModalOpen}
              />
            ))}
          </Box>
          <Box className={classes.unusedSensorsAmount}>{sensorsUnused}</Box>
          <Box className={classes.saveAllButtonWrapper}>
            <Box
              className={classes.saveAllButton}
              onClick={handleSubmitSensors}
            >
              <Trans>Сохранить медиаплан и вернуться на страницу проекта</Trans>
            </Box>
          </Box>
        </Box>
        {isDisplayingModal ? (
          <SensorListModal
            icon={selectedGroupIcon}
            heading={selectedGroupHeading}
            onClose={handleModalClose}
            onSave={handleModalSave}
            list={allSensors}
            groups={groups}
          />
        ) : null}
        {isDisplayingForm && (
          <DuplicateForm onClose={handleFormClose} companyId={companyId} />
        )}
      </StyledBox>
    </>
  );
};

export default Sensors;
