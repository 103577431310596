/** @format */
import {Box, Button} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import BarChartIcon from '@mui/icons-material/BarChart';

const PREFIX = 'DigitalCastButton';

const classes = {
  button: `${PREFIX}-button`,
  buttonIcon: `${PREFIX}-buttonIcon`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.button}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(5, 0),
    borderRadius: '8px',
    background: '#202020',
  },
  [`& .${classes.buttonIcon}`]: {
    marginRight: theme.spacing(2),
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '100%',
  },
}));

const DigitalCastButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/home/digital-cast');
  };
  return (
    <StyledBox>
      <Button className={classes.button} onClick={handleClick}>
        <BarChartIcon className={classes.buttonIcon} />
        <Trans>Цифровой слепок</Trans>
      </Button>
    </StyledBox>
  );
};

export default DigitalCastButton;
