import {ReactElement} from 'react';

import {format} from 'date-fns/format';
import {ru} from 'date-fns/locale/ru';
import {Trans} from 'react-i18next';

export const getDayOfWeekInGenitive = (date: Date): string | ReactElement => {
  const day = format(date, 'eeee', {locale: ru});
  const genitiveMap: Record<string, ReactElement> = {
    понедельник: <Trans>понедельника</Trans>,
    вторник: <Trans>вторника</Trans>,
    среда: <Trans>среды</Trans>,
    четверг: <Trans>четверга</Trans>,
    пятница: <Trans>пятницы</Trans>,
    суббота: <Trans>субботы</Trans>,
    воскресенье: <Trans>воскресенья</Trans>,
  };
  return genitiveMap[day] || day;
};