/** @format */

import {styled} from '@mui/material/styles';
import {Box, Typography} from '@mui/material';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import MediumTextField from 'components/inputs/MediumTextField';
import PhoneNumberField from 'components/inputs/PhoneNumberField';

export const Root = styled(Box)(() => ({
  ['.Modal-outerContainer']: {
    width: '600px',
  },
  ['.MuiBackdrop-root .Modal-headerTextNew']: {
    maxWidth: '350px',
    fontSize: '20px',
  },
}));

export const DescriptionText = styled(Typography)(({theme}) => ({
  fontSize: '14px',
  marginBottom: theme.spacing(6),
}));

export const ConnectText = styled(Typography)(({theme}) => ({
  fontSize: '14px',
  marginBottom: theme.spacing(4),
}));

export const StyledForm = styled('form')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

export const StyledErrorText = styled(Typography)(({theme}) => ({
  color: 'red',
  margin: theme.spacing(1, 0),
  fontSize: '13px',
}));

export const BoxNote = styled(Box)(({theme}) => ({
  borderRadius: theme.spacing(4),
  background: '#F1F1F1',
  display: 'flex',
  gap: theme.spacing(4),
  alignItems: 'center',
  padding: theme.spacing(4),
  margin: theme.spacing(2, 0, 2, 0),
  fontSize: '14px',
}));

export const StyledSubmitButton = styled(PrimaryMediumButton)(() => ({
  width: 'max-content',
  fontWeight: 400,
  fontSize: '14px',
}));

export const StyledMediumTextField = styled(MediumTextField)(() => ({
  ['&.MuiFormControl-root .MuiInputBase-root']: {
    fontSize: '14px',
    ['.MuiInputAdornment-root']: {
      marginRight: 0,
    },
  },
}));

export const StyledPhoneNumberField = styled(PhoneNumberField)(({theme}) => ({
  ['.MuiInputBase-root']: {
    height: '48px',
    ['.MuiInputBase-input']: {
      color: `${theme.palette.text.secondary}`,
      padding: theme.spacing(3.5, 4),
    },
    ['.MuiInputAdornment-root']: {
      marginRight: 0,
    },
  },
  '& fieldset': {
    borderColor: '#DADADA',
  },
}));
