/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'TableBody';

const classes = {
  container: `${PREFIX}-container`,
};

const Root = styled('div')(({_theme}) => ({
  width: '100vw',
  [`& .${classes.container}`]: {
    minWidth: '1885px',
  },
}));

interface Props {
  children: React.ReactNode;
}

const TableBody = (props: Props) => {
  return (
    <Root>
      <Box className={classes.container}>{props.children}</Box>
    </Root>
  );
};

export default TableBody;
