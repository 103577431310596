/** @format */

import {Route, Routes, useLocation} from 'react-router-dom';
import {useFlags} from 'launchdarkly-react-client-sdk';

import MainLayoutNew from 'layouts/MainLayoutNew';

import PrivateRoute from 'components/PrivateRoute';

import Wizard from 'pages/Wizard/Modals';

import {ROUTES} from 'constants/ROUTES';

import ConfirmDeleteCompany from '../Settings/ConfirmDeleteCompany';

import Created from './Steps/Created';
import Edit from './Steps/Edit';
import New from './Steps/New';
import Type from './Steps/Type';
import Periods from './Steps/Periods';
import Sensors from './Steps/Sensors';
import WizardConsult from './Steps/WizardConsult';
import WizardCreated from './Steps/WizardCreated';
import WizardNew from './Steps/WizardNew';

const Project = () => {
  const location = useLocation();
  const {isMediaplanSensorsSelectionEnabled} = useFlags();

  const background = location.state && location.state.background;

  return (
    <MainLayoutNew>
      <Routes location={background || location}>
        <Route
          path={`${ROUTES.PROJECT_NEW}/*`}
          element={
            <PrivateRoute>
              <New />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.PROJECT_TYPE}/*`}
          element={
            <PrivateRoute>
              <Type />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.PROJECT_PERIODS}/*`}
          element={
            <PrivateRoute>
              <Periods />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.PROJECT_WIZARD_NEW}/*`}
          element={
            <PrivateRoute>
              <WizardNew />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.PROJECT_CREATED}/*`}
          element={
            <PrivateRoute>
              <Created />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.PROJECT_WIZARD_CREATED}/*`}
          element={
            <PrivateRoute>
              <WizardCreated />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.PROJECT_WIZARD_CONSULT}/*`}
          element={
            <PrivateRoute>
              <WizardConsult />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.PROJECT_CONFIRM_DELETE_COMPANY}
          element={
            <PrivateRoute>
              <ConfirmDeleteCompany />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.PROJECT_EDIT}/*`}
          element={
            <PrivateRoute>
              <Edit />
            </PrivateRoute>
          }
        />
        {isMediaplanSensorsSelectionEnabled ? (
          <Route
            path={`${ROUTES.PROJECT_SENSORS}/*`}
            element={
              <PrivateRoute>
                <Sensors />
              </PrivateRoute>
            }
          />
        ) : null}
      </Routes>
      {background && (
        <Routes>
          <Route
            path={`${ROUTES.PROJECT_CREATED_SUBACCOUNTS}/`}
            element={
              <PrivateRoute>
                <Wizard.Subaccounts />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.PROJECT_CREATED_WIZARD_SUBACCOUNTS}/`}
            element={
              <PrivateRoute>
                <Wizard.Subaccounts />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.PROJECT_EDIT_SUBACCOUNTS}/`}
            element={
              <PrivateRoute>
                <Wizard.Subaccounts />
              </PrivateRoute>
            }
          />
        </Routes>
      )}
    </MainLayoutNew>
  );
};

export default Project;
