/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'MEDIAPLAN_SIDEBAR';

export const classes = {
  selectableList: `${PREFIX}-selectableList`,
  selectableListItemWrapper: `${PREFIX}-selectableListItemWrapper`,
  selectableListItemWrapperExpanded: `${PREFIX}-selectableListItemWrapperExpanded`,
  selectableListItemIconWrapper: `${PREFIX}-selectableListItemIconWrapper`,
  selectableListItemExpander: `${PREFIX}-selectableListItemExpander`,
  selectableListItemExpanderWrapper: `${PREFIX}-selectableListItemExpanderWrapper`,
  selectableListCategory: `${PREFIX}-selectableListCategory`,
  selectableListItemContent: `${PREFIX}-selectableListItemContent`,
  selectableListItemAssigned: `${PREFIX}-selectableListItemAssigned`,
  selectableListCheckbox: `${PREFIX}-selectableListCheckbox`,
  selectableListCategoryIcon: `${PREFIX}-selectableListCategoryIcon`,

  sensorGroupWrapper: `${PREFIX}-sensorGroupWrapper`,
  sensorGroupSubWrapper: `${PREFIX}-sensorGroupSubWrapper`,
  sensorListHeader: `${PREFIX}-sensorListHeader`,
  sensorListNameWrapper: `${PREFIX}-sensorListNameWrapper`,
  sensorAddButton: `${PREFIX}-sensorAddButton`,
  sensorListWrapper: `${PREFIX}-sensorListWrapper`,
  sensorListItem: `${PREFIX}-sensorListItem`,
  sensorListItemLabel: `${PREFIX}-sensorListItemLabel`,
  sensorListItemIcon: `${PREFIX}-sensorListItemIcon`,
  sensorListModalHeader: `${PREFIX}-sensorListModalHeader`,
  sensorListModalWrapper: `${PREFIX}-sensorListModalWrapper`,

  sensorCounter: `${PREFIX}-sensorCounter`,
};

export const StyledBox = styled(Box)(({theme}) => ({
  [`&:has(.${classes.selectableList})`]: {
    borderRadius: 8,
    overflow: 'hidden',
  },
  [`& .${classes.selectableList}`]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    maxHeight: 430,
    minWidth: 600,
    overflow: 'auto',
    borderRadius: 8,
    display: 'flex',
    background: theme.palette.figma_gray_light_1?.main,
    color: theme.palette.figma_gray_dark_1?.main,
  },
  [`& .${classes.selectableListCategory}`]: {
    background: theme.palette.figma_gray_light_0?.main,
    display: 'flex',
    alignItems: 'center',
    minHeight: 48,
    cursor: 'pointer',
  },
  [`& .${classes.selectableListCheckbox}`]: {
    padding: theme.spacing(3),
    color: theme.palette.figma_gray_middle_dark?.main,

    [`&.Mui-checked,
      &.MuiCheckbox-indeterminate`]: {
      color: '#547AE0',
    },
  },
  [`& .${classes.selectableListCategoryIcon}`]: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(1),
    color: theme.palette.figma_gray_middle_dark?.main,
  },
  [`& .${classes.selectableListItemExpander}`]: {
    opacity: 0,
    color: theme.palette.figma_gray_middle_dark?.main,
  },
  [`& .${classes.selectableListItemIconWrapper}`]: {
    padding: theme.spacing(3),
    width: 48,
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  [`& .${classes.selectableListItemWrapper},
    & .${classes.selectableListItemWrapperExpanded}`]: {
    background: theme.palette.figma_gray_light_1?.main,
    color: theme.palette.figma_gray_dark_1?.main,
    minHeight: 48,
    fontSize: 14,
    lineHeight: '16px',
    borderRadius: 0,
    boxShadow: 'none',

    '&:hover': {
      background: theme.palette.figma_gray_light_2?.main,
    },
    [`&:hover .${classes.selectableListItemExpander}`]: {
      opacity: 1,
    },
    [`&.${classes.selectableListItemAssigned}:hover`]: {
      background: theme.palette.figma_gray_light_1?.main,

      '& .MuiAccordionSummary-root': {
        cursor: 'default',
      },
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      margin: 0,
    },
    '& .MuiAccordionDetails-root': {
      paddingTop: 0,
    },
    '& .MuiAccordionSummary-gutters': {
      padding: 0,
    },
  },
  [`& .${classes.selectableListItemWrapperExpanded}`]: {
    background: theme.palette.figma_gray_light_2?.main,

    [`& .${classes.selectableListItemExpander}`]: {
      opacity: 1,
    },
  },
  [`& .${classes.selectableListItemContent}`]: {
    paddingInline: theme.spacing(10),
    fontSize: 14,
    lineHeight: '16px',
  },

  [`&.${classes.sensorGroupWrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    width: '100%',
    backgroundColor: theme.palette.figma_gray_dark_1?.main,
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  [`& .${classes.sensorGroupSubWrapper}`]: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  [`& .${classes.sensorListNameWrapper}`]: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'start',
  },
  [`& .${classes.sensorListHeader}`]: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  [`& .${classes.sensorListWrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflow: 'auto',
    maxHeight: '40vh',
    scrollbarWidth: 'thin',
    scrollbarColor: '#5a5a5a #0003',
  },
  [`& .${classes.sensorAddButton}`]: {
    minHeight: '48px',
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(4),
    fontSize: '14px',
    color: theme.palette.figma_gray_middle_dark?.main,
  },

  [`&.${classes.sensorListItem}`]: {
    display: 'flex',
    borderTop: '1px solid #383838',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingBlock: theme.spacing(1),
  },
  [`& .${classes.sensorListItemLabel}`]: {
    fontSize: '14px',
  },
  [`& .${classes.sensorListItemIcon}`]: {
    fontSize: '16px',
  },
  [`&.${classes.sensorListModalHeader}`]: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'start',
  },
  [`&.${classes.sensorListModalWrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },

  [`& .${classes.sensorCounter}`]: {
    marginLeft: 'auto',
    textWrap: 'nowrap',
    color: '#F1F1F1',
    fontVariantNumeric: 'lining-nums tabular-nums',
    fontSize: 14,
    lineHeight: '16px',
  },
}));
