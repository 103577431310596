/** @format */
import {ChangeEvent, useEffect, useState} from 'react';

import {Box, Grid, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';

import MediumTextField from 'components/inputs/MediumTextField';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondarySelect from 'components/selects/SecondarySelect';
import {Operator} from 'types';
import {fieldName} from 'helpers/field_names';

type Props = {
  field: string;
  operator?: Operator;
  value?: string;
  onSave: (field: string, operator: Operator, value: string) => void;
};

export const TEXT_OPERATORS: Operator[] = [
  'EQUALS',
  'NOT_EQUALS',
  'NOT_CONTAINS',
  'CONTAINS',
  'START_WITH',
  'NOT_START_WITH',
  'END_WITH',
  'NOT_END_WITH',
];
export const MATH_OPERATORS: Operator[] = [
  'EQUALS',
  'NOT_EQUALS',
  'LESS',
  'MORE',
  'LESS_OR_EQ',
  'MORE_OR_EQ',
];
export const DATE_OPERATORS: Operator[] = ['EQUALS', 'NOT_EQUALS'];

export const NUMBER_FIELDS = [
  'actual_goal_price',
  'ad_title_length',
  'visits',
  'ad_title_restriction_length',
  'adgroup_most_common_ad_count',
  'amount_remaining',
  'autotargeting_bid_network',
  'autotargeting_bid_search',
  'average_pos',
  'average_spend',
  'bid',
  'bounce_rate',
  'bounce_rate_percent',
  'clicks',
  'clicks_analysis',
  'clicks_before',
  'clicks_comparison',
  'clicks_delta_percent',
  'conversion_analysis',
  'conversion_comparison',
  'conversion_rate',
  'conversions',
  'conversions_before',
  'cpa',
  'cpa_analysis',
  'cpa_comparison',
  'cpa_limit',
  'cpc',
  'cpc_actual',
  'cpc_analysis',
  'cpc_comparison',
  'cpc_limit',
  'cpm_actual',
  'cpm_analysis',
  'cpm_comparison',
  'cpm_limit',
  'cr_analysis',
  'cr_comparison',
  'crr',
  'ctr',
  'ctr_analysis',
  'ctr_before',
  'ctr_comparison',
  'day_limit',
  'delta_percent',
  'feed_offer_title_len',
  'feed_offer_title_len_limit',
  'first_page_cpc',
  'impressions',
  'impressions_before',
  'limit',
  'limit_goal_price',
  'median_ctr',
  'metric_diff',
  'metric_diff_abs',
  'metric_previous_value',
  'metric_value',
  'need_count',
  'overspent',
  'overspent_percent',
  'ratio',
  'spent',
  'spent_analysis',
  'spent_before',
  'spent_comparison',
  'spent_delta_percent',
  'spent_keys_conv',
  'spent_keys_noconv',
  'spent_ratio_keys_noconv',
  'spent_to_limit_percent',
  'views',
  'views_before',
  'views_per_user',
  'views_analysis',
  'views_comparison',
];
export const DATE_FIELDS = ['date', 'last_changed'];

export const ExceptionForm = (props: Props) => {
  const [operator, setOperator] = useState(props.operator || TEXT_OPERATORS[0]);
  const [valueInputType, setValueInputType] = useState('text');
  const [value, setValue] = useState(props.value || '');
  const [operators, setOperators] = useState(TEXT_OPERATORS);
  const {t} = useTranslation();

  useEffect(() => {
    if (NUMBER_FIELDS.includes(props.field)) {
      setOperators(MATH_OPERATORS);
      setValueInputType('number');
    } else if (DATE_FIELDS.includes(props.field)) {
      setOperators(DATE_OPERATORS);
      setValueInputType('date');
    } else {
      setValueInputType('text');
    }
  }, [props.field]);

  const options = operators.map(v => ({
    value: v,
    text: t(`operators.${v}`),
  }));

  const handleSelectOperator = (value: string) => {
    setOperator(value as Operator);
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClick = () => {
    props.onSave(props.field, operator, value);
  };

  return (
    <Box
      sx={{
        pt: 8,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid item xs={6} sm={3}>
          <Typography variant='body1'>{t(fieldName(props.field))}</Typography>
        </Grid>
        <Grid item xs={6} sm={5}>
          <SecondarySelect
            options={options}
            value={operator}
            width={170}
            onSelect={handleSelectOperator}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MediumTextField
            value={value}
            type={valueInputType}
            required
            inputProps={{'aria-label': 'exception-value-input'}}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 9,
        }}
      >
        <PrimaryMediumButton disabled={value == ''} onClick={handleClick}>
          <Trans>Сохранить</Trans>
        </PrimaryMediumButton>
      </Box>
    </Box>
  );
};
