/** @format */
import {SyntheticEvent, useState} from 'react';

import {ArrowDropDown} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import {Trans} from 'react-i18next';

import {sensorName} from 'helpers/sensor_names';
import {sensorText, useTexts} from 'helpers/sensorsTextsHelpers';

import {Group} from '..';
import {classes, StyledBox} from './styles';

interface SelectableListItemProps {
  id: string;
  group?: Group;
  isSelected: boolean;
  onSelect: (id: string) => void;
}

export const SelectableListItem = ({
  id,
  group,
  isSelected,
  onSelect,
}: SelectableListItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const label = sensorName(id);
  const sensorTexts = useTexts() as {[key: string]: sensorText};
  const handleSelect = (e: SyntheticEvent) => {
    e.stopPropagation();
    onSelect(id);
  };
  const handleExpand = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsExpanded(pre => !pre);
  };
  const wrapperClassName = clsx(
    isExpanded
      ? classes.selectableListItemWrapperExpanded
      : classes.selectableListItemWrapper,
    Boolean(group) && classes.selectableListItemAssigned,
  );

  return (
    <StyledBox>
      <Accordion className={wrapperClassName} expanded={isExpanded}>
        <AccordionSummary
          onClick={handleSelect}
          expandIcon={
            <IconButton
              onClick={handleExpand}
              className={classes.selectableListItemExpanderWrapper}
            >
              <ArrowDropDown className={classes.selectableListItemExpander} />
            </IconButton>
          }
        >
          {group?.icon ? (
            <Box className={classes.selectableListItemIconWrapper}>
              {group.icon}
            </Box>
          ) : (
            <Checkbox
              checked={isSelected}
              className={classes.selectableListCheckbox}
              color='default'
            />
          )}
          <Typography>
            <Trans>{label}</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.selectableListItemContent}>
            {sensorTexts[id].short_info.CONTEXT}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </StyledBox>
  );
};
