/** @format */

import {gql} from '@apollo/client';

export const CREATE_COPY_SENSOR_GROUPS_ORDER = gql`
  mutation CreateCopySensorGroupsOrder(
    $companyId: ID!
    $input: TeamNotificationsCopySensorGroupsInput!
  ) {
    createCopySensorGroupsOrder(companyId: $companyId, input: $input)
  }
`;
