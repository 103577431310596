/** @format */

import {Box} from '@mui/material';
import {format, differenceInDays} from 'date-fns';
import {ru} from 'date-fns/locale';

import theme from 'theme';

import type {Period} from '../../interfaces';
import {
  DayBox,
  MonthBox,
  PeriodStartDay,
  StartPeriodLine,
  StyledBox,
} from './styles';
import {classes} from '../constants';
import {getDaysRangeFromMonths} from '../utils';
import ChartPeriods from '../ChartPeriods';

interface Props {
  startDate?: Date;
  endDate?: Date;
  periods?: Period[];
}

const Chart = ({endDate, startDate, periods}: Props) => {
  if (!startDate || !endDate) return null;

  const daysAmount = differenceInDays(endDate, startDate) + 1;
  const {monthsAmount, monthWidthAmount} = getDaysRangeFromMonths(
    startDate,
    endDate,
  );

  const timelineWidth = theme.breakpoints.values.md;

  const dayWidth = (100 * daysAmount) / timelineWidth;

  const currentDayStartOffset =
    (differenceInDays(new Date(), startDate) / daysAmount) * 100;

  return (
    <StyledBox className={classes.chartContainer}>
      <Box className={classes.daysWrapper}>
        <StartPeriodLine type='repeatable' left={currentDayStartOffset} />

        {[...Array(daysAmount)].map((index: number) => (
          <DayBox key={index} width={dayWidth} />
        ))}
        {periods &&
          periods.length > 0 &&
          periods.map((period: Period) =>
            period ? (
              <ChartPeriods
                key={period.id}
                period={period}
                startDate={startDate}
                daysAmount={daysAmount}
              />
            ) : null,
          )}
      </Box>
      <Box className={classes.monthsWrapper}>
        <PeriodStartDay left={currentDayStartOffset}>
          {format(new Date(), 'd', {locale: ru})}
        </PeriodStartDay>
        {monthsAmount.map((month: string, index: number) => (
          <MonthBox
            key={index}
            amount={monthWidthAmount[index]}
            sx={{
              fontSize: '14px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {month}
          </MonthBox>
        ))}
      </Box>
    </StyledBox>
  );
};

export default Chart;
