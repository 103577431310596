/** @format */

import {format, startOfMonth, endOfMonth} from 'date-fns';
import {ru} from 'date-fns/locale';
import {differenceInDays} from 'date-fns/differenceInDays';
import {capitalize} from '@mui/material';

export const getDaysRangeFromMonths = (startDate: Date, endDate: Date) => {
  const result = [];

  let start = new Date(startDate);
  const end = new Date(endDate);

  const totalDays = differenceInDays(end, start) + 1;

  while (start <= end) {
    const monthStart = startOfMonth(start);
    const monthEnd = endOfMonth(start);
    const displayMonth = format(start, 'LLLL', {locale: ru});

    const monthStartDate = monthStart < start ? start : monthStart;
    const monthEndDate = monthEnd > end ? end : monthEnd;

    const daysInPeriod = differenceInDays(monthEndDate, monthStartDate) + 1;
    const width = (daysInPeriod / totalDays) * 100;

    result.push({
      month: capitalize(displayMonth),
      startDate: monthStart,
      endDate: monthEnd,
      width: width,
    });

    start = new Date(monthEnd.setDate(monthEnd.getDate() + 1));
  }

  return {
    monthsAmount: result.map(item => item.month),
    monthWidthAmount: result.map(item => item.width),
  };
};
