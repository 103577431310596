/** @format */
import {ReactNode, useCallback, useState} from 'react';

import {Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';

import Modal from 'components/Modal';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import MediumTextField from 'components/inputs/MediumTextField';
import {useDebounce} from 'helpers/useDebounce';

import {SelectableList} from './SelectableList';
import {classes, StyledBox} from './styles';
import {Group} from '..';

export interface SensorListModalProps {
  icon: ReactNode;
  heading: string;
  list: string[];
  groups: Group[];
  onSave: (ids: Set<string>) => void;
  onClose: () => void;
}

export const SensorListModal = ({
  icon,
  list,
  heading,
  onClose,
  onSave,
  groups,
}: SensorListModalProps) => {
  const {t} = useTranslation();
  const [filter, setFilter] = useState('');
  const [selected, setSelected] = useState(new Set<string>());
  const handleUpdate = useCallback((item: string | Set<string>) => {
    if (typeof item === 'string') {
      setSelected(prev => {
        const updated = new Set(prev);
        if (prev.has(item)) {
          updated.delete(item);
        } else {
          updated.add(item);
        }
        return updated;
      });
    } else {
      setSelected(new Set(item));
    }
  }, []);
  const debouncedFilter = useDebounce<string>(filter.trim().toLowerCase());

  const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target?.value);
  }, []);

  const handleSave = () => {
    onSave(new Set(selected));
    onClose();
  };

  return (
    <Modal
      header={
        <StyledBox className={classes.sensorListModalHeader}>
          {icon}
          <Typography variant='h3' sx={{fontSize: 20}}>
            <Trans>{heading}</Trans>
          </Typography>
        </StyledBox>
      }
      onClose={onClose}
    >
      <StyledBox className={classes.sensorListModalWrapper}>
        <MediumTextField
          placeholder={t('Найти сенсор', 'Найти сенсор')}
          inputProps={{name: 'name'}}
          onChange={handleInput}
        />
        <SelectableList
          selected={selected}
          list={list}
          groups={groups}
          filter={debouncedFilter}
          onUpdate={handleUpdate}
        />
        <PrimaryMediumButton onClick={handleSave} sx={{width: 128}}>
          ОК
        </PrimaryMediumButton>
      </StyledBox>
    </Modal>
  );
};
