/** @format */

import {styled} from '@mui/material/styles';
import {Box, Switch as AntSwitch, Tabs, Alert} from '@mui/material';

import {classes} from './constants';

export const StyledInfoAlert = styled(Alert)(({theme}) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
  '& .MuiAlert-message': {
    fontSize: '14px',
  },
}));

export const StyledTabs = styled(Tabs)(({theme}) => ({
  minHeight: 'unset',
  '& .MuiTabs-flexContainer': {
    gap: 0,
    background: '#202020',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTab-root': {
    minHeight: 56,
    padding: theme.spacing(0, 3),
    borderRadius: 0,
    background: '#202020',
    color: '#8B8B8B',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    textTransform: 'none',
    minWidth: 'unset',
    '&.Mui-selected': {
      background: '#383838',
      color: '#F1F1F1',
      borderBottom: '2px solid #86B52D',
    },
    '&:not(:last-child)': {
      borderRight: '1px solid rgba(255, 255, 255, 0.1)',
    },
  },
  '& .MuiTabScrollButton-root': {
    display: 'none',
  },
}));

export const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.heading}`]: {
    marginTop: theme.spacing(4),
    color: '#F1F1F1',
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: '40px',
  },
  [`& .${classes.controls}`]: {
    top: 0,
    width: '100%',
    marginTop: theme.spacing(8),
    padding: theme.spacing(4, 0),
    background: '#010202',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  [`& .${classes.budgetPlanWrapper}`]: {
    position: 'relative',
  },
  [`& .${classes.budgetPerDayTextWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '30%',
  },
  [`& .${classes.controlsWrapper}`]: {
    height: '48px',
    marginRight: theme.spacing(4),
    borderRadius: '8px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(4),
    },
  },
  [`& .${classes.controlButton}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '143%',
    padding: theme.spacing(3.5, 4),
    letterSpacing: '0.25px',
    borderRadius: 0,
    background: '#202020',
  },
  [`& .${classes.controlButtonActive}`]: {
    color: '#F1F1F1',
    background: '#2B2B2B',
  },
  [`& .${classes.swipeButton}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '143%',
    padding: theme.spacing(3.5, 4),
    letterSpacing: '0.25px',
    borderRadius: 0,
    background: '#202020',
    ['&:nth-of-type(2)']: {
      transform: 'rotate(180deg)',
    },
  },
  [`& .${classes.addPeriodButton}`]: {
    padding: theme.spacing(5, 4),
    borderRadius: '8px',
    background: '#202020',
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.periodInputIcon}`]: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.addPeriodButtonIcon}`]: {
    marginRight: theme.spacing(2),
    color: '#8B8B8B',
    fontSize: '16px',
    lineHeight: '100%',
  },
  [`& .${classes.periodsTabsWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(8),
  },
  [`& .${classes.periodsTabRemoveButton}`]: {
    width: 40,
    minWidth: 'auto',
    height: 40,
    marginLeft: theme.spacing(2),
    color: '#F1F1F1',
    fontSize: '14px',
    lineHeight: '16px',
  },
  [`& .${classes.periodsTabRemoveButtonIcon}`]: {
    color: '#8B8B8B',
    fontSize: '20px',
    lineHeight: '100%',
  },
  [`& .${classes.periodForm}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginTop: theme.spacing(8),
    '& > *': {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(4),
    },
  },
  [`& .${classes.periodFormInput}`]: {
    maxWidth: '250px',
    width: '100%',
    zIndex: 100,
    background: 'black',
    position: 'relative',
  },
  [`& .${classes.periodButtonsBlock}`]: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '8px',
  },
  [`& .${classes.periodButton}`]: {
    borderRadius: 0,
    minHeight: '56px',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    background: '#202020',
  },
  [`& .${classes.dateButton}`]: {
    borderRadius: '8px',
    minHeight: '56px',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    background: '#202020',
  },
  [`& .${classes.periodButtonActive}`]: {
    background: '#383838',
  },
  [`& .${classes.periodButtonIcon}`]: {
    marginLeft: theme.spacing(2),
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '100%',
  },
  [`& .${classes.periodButtonIconBlue}`]: {
    color: '#547AE0',
  },
  [`& .${classes.periodButtonIconGreen}`]: {
    color: '#749F23',
  },
  [`& .${classes.periodSwitchWrapper}`]: {
    maxWidth: '250px',
    width: '100%',
    padding: theme.spacing(0, 3),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#202020',
    minHeight: '56px',
  },
  [`& .${classes.periodSwitchDescription}`]: {
    maxWidth: '250px',
    opacity: 0.5,
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.periodFormHeading}`]: {
    color: '#F1F1F1',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.24px',
  },
  [`& .${classes.periodFormDescription}`]: {
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.budgetPerDayWrapper}`]: {
    display: 'flex',
    padding: theme.spacing(2, 4),
    borderRadius: '8px',
    background: '#383838',
    width: '100%',
    height: '100%',
    maxWidth: '390px',
    justifyContent: 'end',
    alignItems: 'center',
    position: 'absolute',
    top: '0px',
  },
  [`& .${classes.budgetPerDayText}`]: {
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'center',
    wordBreak: 'break-word',
  },
}));

export const PeriodsTabBox = styled(Box)<{isActive: boolean}>(
  ({isActive, theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(4),
    padding: theme.spacing(0, 3),
    height: 56,
    borderBottom: '2px solid #86B52D',
    borderRadius: '8px',
    background: isActive ? '#383838' : '#202020',
    color: isActive ? '#F1F1F1' : '#8B8B8B',
    cursor: 'pointer',
  }),
);

export const Switch = styled(AntSwitch)(({theme}) => ({
  width: 52,
  height: 32,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 30,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(18px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 24,
    height: 24,
    borderRadius: 12,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 32,
  },
}));
