/** @format */
import React from 'react';

import {AsYouType} from 'libphonenumber-js';
import {useTranslation} from 'react-i18next';

import LegacyTextField from 'components/inputs/LegacyTextField';

interface Props {
  value?: string;
  onChange: (value: string) => void;
  slotProps?: {
    input?: {startAdornment?: JSX.Element};
    htmlInput?: {
      maxLength?: number;
      'data-testid'?: string;
    };
  };
  disabled?: boolean;
  placeholder?: string;
}

const PhoneNumberField = ({
  value,
  onChange,
  slotProps,
  disabled,
  placeholder,
  ...attrs
}: Props) => {
  const {t} = useTranslation();
  const formattedValue = new AsYouType().input(value || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    const formattedValue = new AsYouType().input(value);
    onChange(formattedValue);
  };

  return (
    <LegacyTextField
      value={formattedValue}
      placeholder={
        placeholder ? placeholder : t('Номер телефона', 'Номер телефона')
      }
      type='tel'
      disabled={disabled}
      myProps={{
        placeholderFocused: t(
          'Например, +7 995 880 40 320',
          'Например, +7 995 880 40 320',
        ),
      }}
      onChange={handleChange}
      slotProps={slotProps}
      {...attrs}
    />
  );
};

export default PhoneNumberField;
