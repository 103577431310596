/** @format */

import {Box, Button} from '@mui/material';
import clsx from 'clsx';

import {StyledButtonGroup, useStyles} from './styles';

export interface ButtonGroupData {
  value: string;
  label: string;
}

export interface ButtonGroupProps {
  data: ButtonGroupData[];
  onClick?: (value: string) => void;
  currentValue?: string;
  disabled?: boolean;
}

function ButtonGroup({
  data,
  currentValue,
  onClick,
  disabled,
}: ButtonGroupProps) {
  const classes = useStyles();

  return (
    <StyledButtonGroup data-testid='button-group'>
      <Box
        sx={{
          display: 'flex',
          borderRadius: '8px',
          overflow: 'hidden',
          width: 'max-content',
        }}
      >
        {data.map(item => (
          <Button
            key={item.value}
            variant='text'
            className={clsx(classes.button, {
              [classes.selected]: currentValue === item.value,
              [classes.disabledColor]: currentValue !== item.value && disabled,
              [classes.disabledBackground]:
                currentValue === item.value && disabled,
            })}
            onClick={() => onClick && onClick(item.value)}
            disabled={disabled}
            data-testid={`type-button-${item.value}`}
          >
            {item.label}
          </Button>
        ))}
      </Box>
    </StyledButtonGroup>
  );
}

export {ButtonGroup};
