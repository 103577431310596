/** @format */
import {Box} from '@mui/material';
import {Trans} from 'react-i18next';
import {clsx} from 'clsx';

import AddIcon from '@mui/icons-material/Add';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import SensorsIcon from '@mui/icons-material/Sensors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import CallSplitIcon from '@mui/icons-material/CallSplit';

import {StyledBox, classes} from '../styles';

const STEPS = [
  {
    isVisible: true,
    name: 'Создание проекта',
    key: 'CREATE',
    icon: <AddIcon className={classes.stepButtonIcon} />,
  },
  {
    isVisible: false,
    name: 'Медиаплан',
    key: 'MEDIAPLAN',
    icon: (
      <LegendToggleIcon
        className={clsx(classes.stepButtonIcon, classes.stepButtonIconHeading)}
      />
    ),
  },
  {
    isVisible: false,
    name: 'Тип кампаний',
    key: 'TYPE',
    icon: (
      <CallSplitIcon
        className={clsx(classes.stepButtonIcon, classes.stepButtonIconRotated)}
      />
    ),
  },
  {
    isVisible: false,
    name: 'Период, бюджет и KPI',
    key: 'PERIODS',
    icon: <CurrencyRubleIcon className={classes.stepButtonIcon} />,
  },
  {
    isVisible: false,
    name: 'Сенсоры',
    key: 'SENSORS',
    icon: <SensorsIcon className={classes.stepButtonIcon} />,
  },
];

type StepStatus = {
  stepKey: string;
  status: 'done' | 'active';
};

interface Props {
  activeStep: string;
  stepStatus?: StepStatus;
  // temporary flag for storybook
  enableAll?: boolean;
  onSaveAll?: (() => void) | null;
  projectName?: string;
}

const SidebarEdit = (props: Props) => {
  // duplicate for storybook
  let steps = [...STEPS];

  if (!props.enableAll) {
    steps = steps.filter(step => step.isVisible);
  }

  return (
    <StyledBox>
      <Box className={classes.sidebar}>
        {steps.map(step => (
          <Box
            key={step.key}
            className={clsx(
              classes.stepButton,
              step.key === 'CREATE' &&
                steps.length === 1 &&
                !props.onSaveAll &&
                classes.stepButtonCreate,
              step.key === 'MEDIAPLAN' && classes.stepButtonHeading,
              props.activeStep === step.key && classes.stepButtonActive,
            )}
          >
            {step.key === 'CREATE' && props.projectName?.length
              ? null
              : step.icon}

            <Trans>{props.projectName || step.name}</Trans>

            {props.stepStatus &&
              props.stepStatus.stepKey === step.key &&
              props.stepStatus.status === 'done' && (
                <CheckCircleIcon className={classes.statusDoneIcon} />
              )}
          </Box>
        ))}
        {props.onSaveAll && (
          <Box
            className={clsx(classes.stepButton, classes.stepButtonSaveAll)}
            onClick={props.onSaveAll}
          >
            <CheckIcon className={classes.stepButtonIcon} />

            <Trans>Завершить настройку</Trans>
          </Box>
        )}
      </Box>
    </StyledBox>
  );
};

export default SidebarEdit;
