/** @format */

import {useState} from 'react';

import {
  Box,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';

import RutubePlayer from 'components/RutubePlayer';
import Modal from 'components/Modal';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import Sidebar from './Sidebar';

interface Props {
  children: React.ReactChild;
}

const DRAWER_WIDTH = 264;

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
  },
  // use padding instead of margin because Firefox ignores marginBottom
  container: {
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(4),
    },
  },
  drawer: {
    flexShrink: 0,
    width: `${DRAWER_WIDTH}px`,
    height: '100%',
  },
  drawerPaper: {
    backgroundColor: '#3A3A3A',
    width: `${DRAWER_WIDTH}px`,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  menu: {
    position: 'relative',
  },
}));

const SHOW_VIDEO = '1';

const WizardLayout = (props: Props) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const searchParams = routerHelpers.useSearchParams();
  const videoOn = searchParams.get('video');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isVideoShow, setIsVideoShow] = useState(videoOn === SHOW_VIDEO);

  const {t} = useTranslation();

  const {mixpanel} = useMixpanelContext();

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleClickPreview = () => {
    setIsVideoShow(!isVideoShow);
    if (!isVideoShow) {
      mixpanel.track('view_wizard_video_modal', {});
      setIsDrawerOpen(false);
    }
  };
  const renderDrawer = () => {
    return (
      <Box className={classes.drawer} sx={{display: {xs: 'none', lg: 'block'}}}>
        <Drawer
          classes={{paper: classes.drawerPaper}}
          open={isDrawerOpen}
          variant='temporary'
          ModalProps={{keepMounted: true}}
          onClose={handleToggleDrawer}
        >
          <Sidebar
            showVideoPreview
            onToggleDrawer={handleToggleDrawer}
            onClickPreview={handleClickPreview}
          />
        </Drawer>

        <Box
          sx={{display: {xs: 'none', lg: 'block'}}}
          className={classes.drawerPaper}
        >
          <Sidebar showVideoPreview onClickPreview={handleClickPreview} />
        </Box>
      </Box>
    );
  };

  const handleClickPreviewClose = () => {
    setIsVideoShow(false);
    if (videoOn === SHOW_VIDEO) {
      searchParams.delete('video');
      navigate({search: searchParams.toString()});
    }
  };

  const renderFooter = () => (
    <PrimaryMediumButton onClick={handleClickPreviewClose}>
      <Trans>Видео просмотрено</Trans>
    </PrimaryMediumButton>
  );

  return (
    <Box className={classes.root}>
      <CssBaseline />
      {renderDrawer()}
      {isVideoShow && (
        <Modal
          header={t('Добро пожаловать в AdSensor!')}
          footer={renderFooter()}
          onClose={handleClickPreviewClose}
        >
          <Box mb={4}>
            <Typography>
              <Trans>Первая настройка кабинета всего на 2 минуты!</Trans>
            </Typography>
          </Box>
          <RutubePlayer videoId='1c8967451187528eae22d34cd1729ae7' />
        </Modal>
      )}
      <Container className={classes.container} maxWidth='md'>
        <Box className={classes.menu}>
          <Box sx={{display: {xs: 'block', lg: 'none'}}}>
            <IconButton
              color='inherit'
              edge='start'
              onClick={handleToggleDrawer}
              size='large'
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
        {props.children}
      </Container>
    </Box>
  );
};

export default WizardLayout;
