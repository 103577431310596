/** @format */

import {ReactNode} from 'react';

import {Box, Button, Typography} from '@mui/material';
import {Trans} from 'react-i18next';

import theme from 'theme';

import {SensorCounter} from './SensorCounter';
import {SensorItem} from './SensorItem';
import {StyledBox, classes} from './styles';

interface SensorGroupProps {
  name: string;
  icon: ReactNode;
  sensors: string[];
  onRemove: (groupName: string, id: string) => void;
  onAdd: (groupName: string) => void;
}

export const SensorGroup = ({
  name,
  icon,
  sensors,
  onRemove,
  onAdd,
}: SensorGroupProps) => {
  const handleAdd = () => onAdd(name);
  const handleRemove = (id: string) => onRemove(name, id);

  const sensorsList = sensors.length ? (
    sensors.map(id => <SensorItem key={id} id={id} onRemove={handleRemove} />)
  ) : (
    <Typography
      variant='body2'
      sx={{color: theme.palette.figma_gray_middle_dark?.main}}
    >
      <Trans>Не заданы</Trans>
    </Typography>
  );

  return (
    <StyledBox className={classes.sensorGroupWrapper}>
      <Box className={classes.sensorGroupSubWrapper}>
        <Box className={classes.sensorListHeader}>
          <Box className={classes.sensorListNameWrapper}>
            {icon}
            <Typography variant='h3' sx={{fontSize: 20}}>
              <Trans>{name}</Trans>
            </Typography>
          </Box>
          <SensorCounter sensors={sensors} />
        </Box>
        <Box className={classes.sensorListWrapper}>{sensorsList}</Box>
      </Box>
      <Button
        variant='text'
        className={classes.sensorAddButton}
        onClick={handleAdd}
      >
        <Trans>Выбрать</Trans>
      </Button>
    </StyledBox>
  );
};
