/** @format */

import {ChangeEvent, FormEvent, useReducer, useState} from 'react';

import {Trans, useTranslation} from 'react-i18next';

import {Box} from '@mui/material';

import {ApolloError, useMutation} from '@apollo/client';

import Alert from '@mui/material/Alert';

import {Flag} from '@mui/icons-material';

import Modal from 'components/Modal';
import {ButtonGroup, ButtonGroupData} from 'components/ButtonGroup';

import {CustomInputAdornment} from './CustomInputAdornment';
import {
  BoxNote,
  ConnectText,
  DescriptionText,
  Root,
  StyledErrorText,
  StyledForm,
  StyledMediumTextField,
  StyledPhoneNumberField,
  StyledSubmitButton,
} from './styles';
import {CREATE_COPY_SENSOR_GROUPS_ORDER} from './mutations';
import {stateReducer} from './stateReducer';
import {StateProps, SubmitPayload} from './model';
import {
  INITIAL_STATE,
  MAX_COMMENT_LENGTH,
  MAX_CONTACT_LENGTH,
  MAX_PHONE_LENGTH,
} from './const';
import {useValidation} from './useValidation';

interface DuplicateFormProps {
  onClose?: () => void;
  companyId: string | null;
}

const DuplicateForm = ({onClose, companyId}: DuplicateFormProps) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

  const [{type, contact, comment, contactErrors}, dispatch] = useReducer(
    stateReducer,
    INITIAL_STATE,
  );

  const {t} = useTranslation();
  const {validateEmail} = useValidation();

  const [createOrder, {loading: submitting}] = useMutation(
    CREATE_COPY_SENSOR_GROUPS_ORDER,
  );

  const buttonData: ButtonGroupData[] = [
    {
      value: 'email',
      label: t('По почте'),
    },
    {
      value: 'telegram',
      label: t('Через Телеграм'),
    },
    {
      value: 'phone',
      label: t('По телефону'),
    },
  ];

  const handleChange = (action: keyof StateProps, payload: unknown) => {
    dispatch({
      action,
      payload,
    });

    dispatch({
      action: 'contactErrors',
      payload: null,
    });

    if (action === 'type') {
      dispatch({
        action: 'contact',
        payload: '',
      });
    }
  };

  const resetForm = () => {
    dispatch({
      action: 'contact',
      payload: '',
    });
    dispatch({
      action: 'comment',
      payload: '',
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitSuccess(false);

    const isValid = validateEmail(contact, type, dispatch);
    if (!isValid) return;

    const payload: SubmitPayload = {
      contact,
    };

    if (comment.trim()) {
      payload.comment = comment.trim();
    }

    try {
      const {data} = await createOrder({
        variables: {
          companyId,
          input: payload,
        },
      });

      if (data?.createCopySensorGroupsOrder) {
        resetForm();
        setSubmitSuccess(true);
      }
    } catch (e: unknown) {
      if (e instanceof ApolloError) {
        setSubmitError(e.message);
      } else {
        setSubmitError(String(e));
      }
    }
  };

  return (
    <Root data-testid='modal-root'>
      <Modal
        header={t('Настройки критичности сенсоров для множества проектов')}
        onClose={onClose}
      >
        <DescriptionText>
          <Trans>
            Чтобы массово применить настройки критичности для нескольких
            проектов, отправьте заявку, а мы сделаем всё остальное
          </Trans>
        </DescriptionText>
        <ConnectText>
          <Trans>Как с вами связаться?</Trans>
        </ConnectText>

        <StyledForm onSubmit={handleSubmit}>
          <ButtonGroup
            data={buttonData}
            currentValue={type}
            onClick={value => handleChange('type', value)}
            disabled={submitting}
          />

          <Box>
            {type === 'phone' ? (
              <StyledPhoneNumberField
                value={contact}
                onChange={value => handleChange('contact', value)}
                disabled={submitting}
                placeholder={t('Телефон для связи')}
                slotProps={{
                  input: {
                    startAdornment: <CustomInputAdornment type={type} />,
                  },
                  htmlInput: {
                    maxLength: MAX_PHONE_LENGTH,
                    'data-testid': 'input-phone',
                  },
                }}
              />
            ) : (
              <StyledMediumTextField
                error={Boolean(contactErrors)}
                helperText={contactErrors}
                placeholder={
                  type === 'email'
                    ? t('Почта для связи')
                    : t('Телеграм-аккаунт')
                }
                value={contact}
                type={type === 'email' ? 'email' : 'text'}
                disabled={submitting}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  handleChange('contact', e.target.value)
                }
                slotProps={{
                  input: {
                    startAdornment: <CustomInputAdornment type={type} />,
                  },
                  htmlInput: {
                    maxLength: MAX_CONTACT_LENGTH,
                    'data-testid': 'input-contact',
                  },
                  formHelperText: {
                    'data-testid': 'error-text',
                  },
                }}
              />
            )}
          </Box>

          <StyledMediumTextField
            placeholder={t('Если нужно, добавьте комментарий')}
            value={comment}
            multiline
            minRows={2}
            disabled={submitting}
            InputProps={{
              inputProps: {
                maxLength: MAX_COMMENT_LENGTH,
                'data-testid': 'input-comment',
              },
            }}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleChange('comment', e.target.value)
            }
          />

          <BoxNote>
            <Flag sx={{color: '#547AE0', fontSize: '32px'}} />
            <Trans>
              Чтобы ускорить процесс, сперва настройте желаемые критичности
              сенсоров в этом проекте и после отправьте заявку
            </Trans>
          </BoxNote>

          <StyledSubmitButton
            disabled={Boolean(
              contactErrors || !contact || submitting || submitSuccess,
            )}
            type='submit'
            data-testid='submit-button'
          >
            <Trans>Отправить заявку</Trans>
          </StyledSubmitButton>

          {submitError && (
            <StyledErrorText>
              <Trans>Ошибка отправки формы</Trans>
            </StyledErrorText>
          )}

          {submitSuccess && (
            <Alert
              sx={{background: '#edf7ed', color: '#3d603e'}}
              data-testid='submit-success'
            >
              <Trans>Запрос отправлен успешно</Trans>
            </Alert>
          )}
        </StyledForm>
      </Modal>
    </Root>
  );
};

export {DuplicateForm};
