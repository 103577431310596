/** @format */

export const COLORS = {
  boxBorder: '#8B8B8B',
  background: '#202020',
  dayBorder: '#383838',
  tabDivider: 'rgba(255, 255, 255, 0.1)',
  text: {
    primary: '#F1F1F1',
    secondary: '#8B8B8B',
  },
  period: {
    repeatable: '#547AE0',
    flight: '#749F23',
  },
} as const;

const PREFIX = 'CHART';

export const classes = {
  daysWrapper: `${PREFIX}-daysWrapper`,
  monthsWrapper: `${PREFIX}-monthsWrapper`,
  chartContainer: `${PREFIX}-chartContainer`,
};
