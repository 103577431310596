/** @format */

const PREFIX = 'TYPE';

export const classes = {
  heading: `${PREFIX}-heading`,
  description: `${PREFIX}-description`,
  alertBlock: `${PREFIX}-alertBlock`,
  alertBlockIcon: `${PREFIX}-alertBlockIcon`,
  controlsWrapper: `${PREFIX}-controlsWrapper`,
  typeButton: `${PREFIX}-typeButton`,
  typeButtonActive: `${PREFIX}-typeButtonActive`,
  typeButtonHeading: `${PREFIX}-typeButtonHeading`,
  typeButtonDescription: `${PREFIX}-typeButtonDescription`,
};
