/** @format */

import {useState, useRef, useEffect, useCallback, useMemo} from 'react';

import {createPortal} from 'react-dom';
import {Trans} from 'react-i18next';
import {differenceInDays, format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {t} from 'i18next';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

import {PeriodBox, PeriodDescription, PeriodHeading} from '../Chart/styles';
import {
  SubPeriodBox,
  SmallPeriodIndicator,
  HoveredPeriodBox,
  HoveredPeriodContent,
  getPeriodStartIndicator,
  getBorderStyles,
  IconContainer,
} from './styles';
import {classes, COLORS} from '../constants';
import {getPeriods, MIN_PERIOD_WIDTH_PIXELS} from './utils';
import {ChartPeriodsProps} from './interfaces';

const ChartPeriods = ({period, startDate, daysAmount}: ChartPeriodsProps) => {
  const [hoveredPeriodIndex, setHoveredPeriodIndex] = useState<number | null>(
    null,
  );
  const [iconPosition, setIconPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [hoverPosition, setHoverPosition] = useState<{
    top: number;
    left: number;
    height: number;
  } | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const periodRef = useRef<HTMLDivElement>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const periodId = useMemo(() => period.id, [period.id]);
  const containerRef = useRef<HTMLElement | null>(null);

  const startOffset = period.startDate
    ? (differenceInDays(period.startDate, startDate) / daysAmount) * 100
    : null;

  const width =
    period.endDate && period.startDate
      ? ((differenceInDays(period.endDate, period.startDate) + 1) /
          daysAmount) *
        100
      : null;

  const subPeriods = getPeriods(period);

  const PeriodContent = ({subPeriod}: {subPeriod: (typeof subPeriods)[0]}) => (
    <>
      {subPeriod.budget && (
        <>
          <PeriodHeading>{subPeriod.budget} ₽</PeriodHeading>
          <PeriodDescription>
            ~ {subPeriod.dailyBudget} ₽ <Trans>в день</Trans>
          </PeriodDescription>
        </>
      )}
      <PeriodHeading>
        {subPeriod.start && format(subPeriod.start, 'd MMMM', {locale: ru})} -{' '}
        {subPeriod.end && format(subPeriod.end, 'd MMMM', {locale: ru})}
      </PeriodHeading>
      <PeriodDescription>
        {t('plurals.days', {count: subPeriod.days})}
      </PeriodDescription>
    </>
  );

  const handleMouseEnter = (index: number, element: HTMLElement) => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    const rect = element.getBoundingClientRect();
    const parentRect = periodRef.current?.getBoundingClientRect();

    const pageScroll = {
      top: window.pageYOffset,
      left: window.pageXOffset,
    };

    if (parentRect) {
      setHoverPosition({
        top: parentRect.top + pageScroll.top,
        left: rect.left + pageScroll.left,
        height: parentRect.height,
      });
      setHoveredPeriodIndex(index);
    }
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setHoveredPeriodIndex(null);
      setHoverPosition(null);
    }, 0);
  };

  const getSubPeriods = (subPeriod: (typeof subPeriods)[0], index: number) => {
    const subPeriodWidth = (containerWidth * subPeriod.width) / 100;
    const isSmallWidth = subPeriodWidth < MIN_PERIOD_WIDTH_PIXELS;
    const isHovered = hoveredPeriodIndex === index;

    return (
      <>
        <SubPeriodBox
          key={index}
          sx={{
            left: `${subPeriod.offset}%`,
            width: `${subPeriod.width}%`,
            borderLeft: getBorderStyles(index, isHovered),
            ...getPeriodStartIndicator(index === 0, period.type),
          }}
          onMouseEnter={e => {
            if (isSmallWidth) handleMouseEnter(index, e.currentTarget);
          }}
          onMouseLeave={handleMouseLeave}
        >
          {isSmallWidth ? (
            <SmallPeriodIndicator>
              {hoveredPeriodIndex === index ? '' : ''}
            </SmallPeriodIndicator>
          ) : (
            <PeriodContent subPeriod={subPeriod} />
          )}
        </SubPeriodBox>
      </>
    );
  };

  const getIcon = () => {
    const Icon = period.type === 'flight' ? MoveDownIcon : AllInclusiveIcon;
    const color =
      period.type === 'flight'
        ? COLORS.period.flight
        : COLORS.period.repeatable;

    return <Icon sx={{color, width: 16, height: 16}} />;
  };

  const updateIconPosition = useCallback(() => {
    if (!periodRef.current) return;

    const periodRect = periodRef.current.getBoundingClientRect();
    const containerElement = document.querySelector(
      `.${classes.chartContainer}`,
    );

    if (!containerElement) return;

    const containerRect = containerElement.getBoundingClientRect();

    const scrollLeft = containerElement.scrollLeft;
    const scrollTop = containerElement.scrollTop;

    setIconPosition({
      top: periodRect.top - containerRect.top + scrollTop - 18,
      left: periodRect.left - containerRect.left + scrollLeft - 6,
    });
  }, []);

  useEffect(() => {
    const container = document.querySelector(`.${classes.chartContainer}`);
    if (!container) return;

    containerRef.current = container as HTMLElement;

    const resizeObserver = new ResizeObserver(() => {
      updateIconPosition();
    });

    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [updateIconPosition]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      updateIconPosition();
    };

    container.addEventListener('scroll', handleScroll);
    updateIconPosition();

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [
    updateIconPosition,
    startDate,
    period.startDate,
    period.endDate,
    daysAmount,
  ]);

  useEffect(() => {
    if (!periodRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      const width = entries[0]?.contentRect.width || 0;
      setContainerWidth(width);
    });

    resizeObserver.observe(periodRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      <PeriodBox
        ref={periodRef}
        key={`period-${periodId}`}
        left={startOffset ?? 0}
        width={width ?? 0}
        type={period.type}
      >
        {subPeriods.map(getSubPeriods)}
      </PeriodBox>
      {period.startDate &&
        iconPosition &&
        createPortal(
          <IconContainer
            sx={{
              top: iconPosition.top,
              left: iconPosition.left,
            }}
          >
            {getIcon()}
          </IconContainer>,
          document.querySelector(`.${classes.chartContainer}`)!,
        )}
      {hoveredPeriodIndex !== null &&
        hoverPosition &&
        createPortal(
          <HoveredPeriodBox
            left={hoverPosition.left}
            width={100}
            type={period.type}
            sx={{
              top: hoverPosition.top,
              left: hoverPosition.left,
              height: hoverPosition.height,
              borderLeft: `1px solid ${COLORS.tabDivider}`,
            }}
            onMouseEnter={() => {
              if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
              }
            }}
            onMouseLeave={handleMouseLeave}
          >
            <HoveredPeriodContent>
              <PeriodContent subPeriod={subPeriods[hoveredPeriodIndex]} />
            </HoveredPeriodContent>
          </HoveredPeriodBox>,
          document.body,
        )}
    </>
  );
};

export default ChartPeriods;
