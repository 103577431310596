/** @format */

import {ControlsPeriod} from './interfaces';

const PREFIX = 'PERIODS';

export const classes = {
  heading: `${PREFIX}-heading`,
  controls: `${PREFIX}-controls`,
  controlsWrapper: `${PREFIX}-controlsWrapper`,
  controlButton: `${PREFIX}-controlButton`,
  controlButtonActive: `${PREFIX}-controlButtonActive`,
  swipeButton: `${PREFIX}-swipeButton`,
  addPeriodButton: `${PREFIX}-addPeriodButton`,
  addPeriodButtonIcon: `${PREFIX}-addPeriodButtonIcon`,
  periodsTabsWrapper: `${PREFIX}-periodsTabsWrapper`,
  periodsTab: `${PREFIX}-periodsTab`,
  periodsTabRemoveButton: `${PREFIX}-periodsTabRemoveButton`,
  periodsTabRemoveButtonIcon: `${PREFIX}-periodsTabRemoveButtonIcon`,
  periodForm: `${PREFIX}-periodForm`,
  periodFormInput: `${PREFIX}-periodFormInput`,
  periodButtonsBlock: `${PREFIX}-periodButtonsBlock`,
  periodButton: `${PREFIX}-periodButton`,
  periodInputIcon: `${PREFIX}-periodInputIcon`,
  periodButtonActive: `${PREFIX}-periodButtonActive`,
  periodButtonIcon: `${PREFIX}-periodButtonIcon`,
  periodButtonIconBlue: `${PREFIX}-periodButtonIconBlue`,
  periodButtonIconGreen: `${PREFIX}-periodButtonIconGreen`,
  periodSwitchWrapper: `${PREFIX}-periodSwitchWrapper`,
  periodSwitchDescription: `${PREFIX}-periodSwitchDescription`,
  periodFormHeading: `${PREFIX}-periodFormHeading`,
  periodFormDescription: `${PREFIX}-periodFormDescription`,
  dateButton: `${PREFIX}-dateButton`,
  budgetPerDayWrapper: `${PREFIX}-budgetPerDayWrapper`,
  budgetPerDayText: `${PREFIX}-budgetPerDayText`,
  budgetPlanWrapper: `${PREFIX}-budgetPlanWrapper`,
  budgetPerDayTextWrapper: `${PREFIX}-budgetPerDayTextWrapper`,
};

export const CONTROLS_PERIODS: {
  key: ControlsPeriod;
  name: string;
}[] = [
  {
    key: 'quarter',
    name: '3 месяца',
  },
  {
    key: 'half-year',
    name: '6 месяцев',
  },
  {
    key: 'year',
    name: 'год',
  },
];

export const YEAR_DAYS = 365;
