/** @format */
import {differenceInDays, startOfMonth, addMonths, add} from 'date-fns';

import {ChartPeriodsProps} from './interfaces';

export const getPeriods = (period: ChartPeriodsProps['period']) => {
  const {startDate: start, endDate: end} = period;

  if (!start || !end || !period.endDate || !period.startDate) return [];

  const periods = [];
  let currentStart = start;

  while (currentStart < end) {
    let nextDate;

    if (period.repeatEvery === 'weekly') {
      if (period.isLinkedWithPeriodStart) {
        const daysUntilMonday = (8 - currentStart.getDay()) % 7;
        nextDate = add(currentStart, {
          days: daysUntilMonday > 0 ? daysUntilMonday - 1 : 6,
        });
      } else {
        nextDate = add(currentStart, {days: 6});
      }
    } else {
      if (period.isLinkedWithPeriodStart) {
        nextDate = add(startOfMonth(addMonths(currentStart, 1)), {days: -1});
      } else {
        nextDate = add(currentStart, {days: 29});
      }
    }

    const periodEnd = nextDate > end ? end : nextDate;
    const days = differenceInDays(periodEnd, currentStart) + 1;

    periods.push({
      start: currentStart,
      end: periodEnd,
      days,
      budget: period.budgetPlan,
      dailyBudget: period.budgetPlan
        ? Math.round(period.budgetPlan / days)
        : null,
      offset:
        (differenceInDays(currentStart, period.startDate) /
          (differenceInDays(period.endDate, period.startDate) + 1)) *
        100,
      width:
        (days / (differenceInDays(period.endDate, period.startDate) + 1)) * 100,
    });

    currentStart = add(periodEnd, {days: 1});
  }

  return periods;
};

export const MIN_PERIOD_WIDTH_PIXELS = 100;
