/** @format */
import {useState} from 'react';

import {clsx} from 'clsx';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SidebarEdit from 'components/MediaplanSidebar/SidebarEdit';

import {classes} from './constants';
import {StyledBox} from './styles';

type MEDIAPLAN_TYPE = 'PERFORMANCE' | 'MEDIA' | null;

const Type = () => {
  const navigate = useNavigate();

  const [mediaplanType, setMediaplanType] = useState<MEDIAPLAN_TYPE>(null);

  const handleTypeChange = (type: MEDIAPLAN_TYPE) => {
    setMediaplanType(type);
  };

  const onSave = async () => {
    navigate('/home/project/period');
  };

  return (
    <StyledBox>
      <SidebarEdit activeStep={'TYPE'} enableAll />

      <Box>
        <Typography className={classes.heading}>
          <Trans>Тип кампаний</Trans>
        </Typography>
      </Box>
      <Box sx={{mt: 8}}>
        <Typography className={classes.description}>
          <Trans>Выберите тип кампании</Trans>
        </Typography>
      </Box>
      <Box sx={{mt: 2}}>
        <Typography className={classes.description}>
          <Trans>
            Этот выбор определит, какие кампании попадут из кабинета в проект, а
            также повлияет на список доступных KPI в медиаплане
          </Trans>
        </Typography>
      </Box>
      <Box className={classes.alertBlock}>
        <PriorityHighIcon className={classes.alertBlockIcon} />
        <Typography className={classes.description}>
          <Trans>
            Тип кампании закрепляется за проектом навсегда. Чтобы анализировать
            оба типа кампаний, создайте два проекта
          </Trans>
        </Typography>
      </Box>

      <Box className={classes.controlsWrapper}>
        <Button
          className={clsx(
            classes.typeButton,
            mediaplanType === 'PERFORMANCE' && classes.typeButtonActive,
          )}
          onClick={() => handleTypeChange('PERFORMANCE')}
        >
          <Typography className={classes.typeButtonHeading}>
            <Trans>За клики</Trans>
          </Typography>
          <Typography className={classes.typeButtonDescription}>
            <Trans>Перформ-кампании</Trans>
          </Typography>
        </Button>

        <Button
          className={clsx(
            classes.typeButton,
            mediaplanType === 'MEDIA' && classes.typeButtonActive,
          )}
          onClick={() => handleTypeChange('MEDIA')}
        >
          <Typography className={classes.typeButtonHeading}>
            <Trans>За показы</Trans>
          </Typography>
          <Typography className={classes.typeButtonDescription}>
            <Trans>Медийные кампании</Trans>
          </Typography>
        </Button>
      </Box>

      {mediaplanType && (
        <Box
          sx={{
            mt: 24,
            mb: 24,
          }}
        >
          <PrimaryMediumButton onClick={onSave}>
            <Trans>Сохранить и перейти к периодам</Trans>
          </PrimaryMediumButton>
        </Box>
      )}
    </StyledBox>
  );
};

export default Type;
