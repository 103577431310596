/** @format */

import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';

import {classes} from './constants';

export const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.heading}`]: {
    color: '#F1F1F1',
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: '40px',
  },
  [`& .${classes.description}`]: {
    maxWidth: '600px',
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  [`& .${classes.alertBlock}`]: {
    display: 'flex',
    maxWidth: '600px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: '8px',
    border: '1px solid #547AE0',
  },
  [`& .${classes.alertBlockIcon}`]: {
    marginRight: theme.spacing(2),
    color: '#547AE0',
    fontSize: '20px',
    fontWeight: 400,
  },
  [`& .${classes.controlsWrapper}`]: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  [`& .${classes.typeButton}`]: {
    width: '50%',
    height: '300px',
    minHeight: '56px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#202020',
    '&:first-child': {
      borderRadius: '8px 0 0 8px',
    },
    '&:last-child': {
      borderRadius: '0 8px 8px 0',
    },
    '&:hover': {
      background: '#383838',
    },
  },
  [`& .${classes.typeButtonActive}`]: {
    background: '#383838',
  },
  [`& .${classes.typeButtonHeading}`]: {
    marginBottom: theme.spacing(4),
    color: '#F1F1F1',
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  [`& .${classes.typeButtonDescription}`]: {
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
}));
