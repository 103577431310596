/** @format */

import {StateProps} from './model';

export const INITIAL_STATE: StateProps = {
  type: 'email',
  contact: '',
  comment: '',
  contactErrors: null,
};

export const MAX_PHONE_LENGTH = 20;
export const MAX_CONTACT_LENGTH = 100;
export const MAX_COMMENT_LENGTH = 1000;
