/** @format */

import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';

import {PeriodBox} from '../Chart/styles';
import {COLORS} from '../constants';

export const SubPeriodBox = styled(Box)<{isSmallWidth?: boolean}>(
  ({theme}) => ({
    position: 'absolute',
    height: '100%',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    overflow: 'hidden',
  }),
);

export const SmallPeriodIndicator = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  fontSize: '14px',
  color: 'rgba(255, 255, 255, 0.8)',
});

export const HoveredPeriodBox = styled(PeriodBox)(({theme, type}) => ({
  position: 'absolute',
  zIndex: 9999,
  width: '150px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(6px)',
  backgroundColor: 'transparent',
  padding: theme.spacing(1),
  borderRight:
    type === 'flight'
      ? `2px solid ${COLORS.period.flight}`
      : `2px solid ${COLORS.period.repeatable}`,
  opacity: 1,
  transform: 'translateY(0)',
  '& > *': {
    backdropFilter: 'none',
  },
}));

export const HoveredPeriodContent = styled(Box)({
  position: 'relative',
  zIndex: 1,
  backdropFilter: 'none',
});

export const IconContainer = styled(Box)({
  position: 'absolute',
  zIndex: 1000,
  pointerEvents: 'none',
});

export const getBorderStyles = (index: number, isHovered: boolean) => {
  if (index === 0) return 'none';
  if (isHovered) return 'none';

  return `1px solid ${COLORS.tabDivider}`;
};

export const getPeriodStartIndicator = (isFirst: boolean, type: string) => {
  if (!isFirst) return {};

  const color =
    type === 'flight' ? COLORS.period.flight : COLORS.period.repeatable;

  return {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '-25px',
      bottom: '-25px',
      width: '2px',
      backgroundColor: color,
      zIndex: 1,
    },
  };
};
