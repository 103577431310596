/** @format */
import React, {ReactNode, useEffect, useMemo, useState} from 'react';

import {
  FormatListBulleted,
  Paid,
  Radar,
  ShowChart,
  ToggleOn,
} from '@mui/icons-material';
import {Box, Checkbox, Typography} from '@mui/material';
import {Trans} from 'react-i18next';

import {sensorName} from 'helpers/sensor_names';
import categoriesData from 'pages/Home/Main/Status/Issues/categories.json';

import {Group} from '..';
import {SelectableListItem} from './SelectableListItem';
import {StyledBox, classes} from './styles';

interface SensorWithName {
  id: string;
  humanName: string;
}

interface CategoriesWithSensorNames {
  name: string;
  title: string;
  description: string;
  sensors: SensorWithName[];
}

interface Sensor {
  group?: Group;
  id: string;
  humanName: string;
}

interface Category {
  sensors: Sensor[];
  name: string;
  title: string;
  description: string;
}

interface SelectableListCategoryProps {
  selected: Set<string>;
  category: Category;
  groups: Group[];
  onUpdate: (item: string | Set<string>) => void;
}

const icons: Record<string, ReactNode> = {
  budget: <Paid className={classes.selectableListCategoryIcon} />,
  campaign_settings: (
    <ToggleOn className={classes.selectableListCategoryIcon} />
  ),
  ads_quality: <ShowChart className={classes.selectableListCategoryIcon} />,
  targeting_semantic_kernel: (
    <Radar className={classes.selectableListCategoryIcon} />
  ),
  feeds: <FormatListBulleted className={classes.selectableListCategoryIcon} />,
};

const categoriesDataWithNames: CategoriesWithSensorNames[] = categoriesData.map(
  el => {
    const sensors = el.sensors.map(sensor => {
      const humanName = sensorName(sensor);
      return {id: sensor, humanName};
    });
    return {...el, sensors};
  },
);

const SelectableListCategory = ({
  selected,
  category,
  groups,
  onUpdate,
}: SelectableListCategoryProps) => {
  const [isSelected, setIsSelected] = useState(
    category.sensors.every(
      el =>
        groups.find(group => group.sensors.includes(el.id)) ||
        selected.has(el.id),
    ),
  );
  const isDisabled = useMemo(
    () =>
      category.sensors.every(el =>
        groups.find(group => group.sensors.includes(el.id)),
      ),
    [category.sensors, groups],
  );

  const isIndeterminate = useMemo(
    () => !isSelected && category.sensors.some(el => selected.has(el.id)),
    [category.sensors, isSelected, selected],
  );

  useEffect(() => {
    setIsSelected(
      category.sensors.every(
        el =>
          groups.find(group => group.sensors.includes(el.id)) ||
          selected.has(el.id),
      ),
    );
  }, [category.sensors, groups, selected]);

  const handleClick = () => {
    setIsSelected(!isSelected);
    const updated = new Set(selected);
    if (isSelected) {
      category.sensors.forEach(({id, group}) => !group && updated.delete(id));
    } else {
      category.sensors.forEach(({id, group}) => !group && updated.add(id));
    }
    onUpdate(updated);
  };

  return (
    <React.Fragment key={category.name}>
      <Box className={classes.selectableListCategory} onClick={handleClick}>
        <Checkbox
          checked={isSelected}
          disabled={isDisabled}
          indeterminate={isIndeterminate}
          className={classes.selectableListCheckbox}
          color='default'
        />
        {icons[category.name]}
        <Typography>
          <Trans>{category.title}</Trans>
        </Typography>
      </Box>
      {category.sensors.map(({id, group}) => (
        <SelectableListItem
          key={`${category.name}_${id}`}
          id={id}
          group={group}
          onSelect={onUpdate}
          isSelected={selected.has(id)}
        />
      ))}
    </React.Fragment>
  );
};

interface SelectableListProps {
  list: string[];
  filter?: string;
  groups: Group[];
  className?: string;
  selected: Set<string>;
  onUpdate: (sensor: string | Set<string>) => void;
}

export const SelectableList = ({
  list,
  groups,
  filter,
  onUpdate,
  className,
  selected = new Set(),
}: SelectableListProps) => {
  const processedCategories = useMemo(() => {
    return categoriesDataWithNames.map(data => ({
      ...data,
      sensors: data.sensors
        .filter(({id, humanName}) => {
          if (filter) {
            return (
              list.includes(id) &&
              humanName.toLowerCase().includes(filter.trim().toLowerCase())
            );
          }
          return list.includes(id);
        })
        .map(sensor => {
          const group = groups.find(group => group.sensors.includes(sensor.id));
          return {...sensor, group};
        }),
    }));
  }, [list, filter, groups]);

  const categoryList = useMemo(() => {
    return processedCategories.map(category => (
      <SelectableListCategory
        selected={selected}
        key={category.name}
        category={category}
        groups={groups}
        onUpdate={onUpdate}
      />
    ));
  }, [groups, onUpdate, selected, processedCategories]);

  return (
    <StyledBox className={className}>
      <Box className={classes.selectableList}>{categoryList}</Box>
    </StyledBox>
  );
};
