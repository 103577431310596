/** @format */

import {useState} from 'react';

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {clsx} from 'clsx';
import {Unstable_Popup as BasePopup} from '@mui/base/Unstable_Popup';
import FilterListIcon from '@mui/icons-material/FilterList';
import {Trans, useTranslation} from 'react-i18next';

import {sensorName} from 'helpers/sensor_names';

import {CHIER_REPORT_TABLE_TYPE} from 'pages/ChiefReport/types';

import ArrowIcon from './ArrowIcon';
import TableCell from './TableCell';
import ResolvedLine from './ResolvedLine';

const PREFIX = 'TableRow';

const classes = {
  container: `${PREFIX}-container`,
  usersWrapper: `${PREFIX}-usersWrapper`,
  usersAmount: `${PREFIX}-usersAmount`,
  arrowIcon: `${PREFIX}-arrowIcon`,
  arrowIconRotated: `${PREFIX}-arrowIconRotated`,
  daysNeutral: `${PREFIX}-daysNeutral`,
  daysAverage: `${PREFIX}-daysAverage`,
  daysNegative: `${PREFIX}-daysNegative`,
  datesContainer: `${PREFIX}-datesContainer`,
  sensorsAmount: `${PREFIX}-sensorsAmount`,
  sensorNameContainer: `${PREFIX}-sensorNameContainer`,
  sensorName: `${PREFIX}-sensorName`,
  resolvedAmount: `${PREFIX}-resolvedAmount`,
  resolvedAmountStaff: `${PREFIX}-resolvedAmountStaff`,
  exceptionsIconWrapper: `${PREFIX}-exceptionsIconWrapper`,
  exceptionsIcon: `${PREFIX}-exceptionsIcon`,
};

const Root = styled(Box)(({theme}) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(2, 2, 2, 0),
    cursor: 'pointer',
    border: '1px solid #2B2B2B',
    '&:hover': {
      background: '#2B2B2B',
      [`& .${classes.usersWrapper}`]: {
        background: '#2B2B2B',
      },
    },
  },
  [`& .${classes.usersWrapper}`]: {
    padding: theme.spacing(1),
    borderRadius: '8px',
    background: '#202020',
  },
  [`& .${classes.usersAmount}`]: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  [`& .${classes.sensorsAmount}`]: {
    color: '#8B8B8B',
    fontWeight: 600,
    lineHeight: '16px',
  },
  [`& .${classes.arrowIcon}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
    transform: 'rotateX(190deg)',
  },
  [`& .${classes.arrowIconRotated}`]: {
    transform: 'rotateX(0deg)',
  },
  [`& .${classes.datesContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.daysNeutral}`]: {
    color: '#8B8B8B',
  },
  [`& .${classes.daysAverage}`]: {
    color: '#DDA14D',
  },
  [`& .${classes.daysNegative}`]: {
    color: '#EA5759',
  },

  [`& .${classes.sensorNameContainer}`]: {
    position: 'relative',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`& .${classes.sensorName}`]: {
    width: '94%',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
  },
  [`& .${classes.resolvedAmount}`]: {
    width: '40px',
    textAlign: 'right',
  },
  [`& .${classes.resolvedAmountStaff}`]: {
    width: '25px',
    textAlign: 'right',
  },
  [`& .${classes.exceptionsIconWrapper}`]: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: '18px',
    height: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    background: '#383838',
  },
  [`& .${classes.exceptionsIcon}`]: {
    color: '#F1F1F1',
    fontSize: '16px',
  },
}));

const PopupBody = styled('div')(
  ({theme}) => `
  width: 320px;
  padding: 12px;
  margin: 8px;
  border-radius: 8px;
  box-shadow: ${
    theme.palette.mode === 'dark'
      ? '0px 4px 8px rgb(0 0 0 / 0.7)'
      : '0px 4px 8px rgb(0 0 0 / 0.1)'
  };
  background: white;
  color: black;
  position: relative;
  z-index: 3
`,
);

interface Props {
  type: CHIER_REPORT_TABLE_TYPE;
  rowData: any;
  amountOfWeeks?: number;
  isExpanded?: boolean;
  onClick?: () => void;
}

const GOOD_AMOUNT_OF_DOWNLOADS = 3;
const OPTIMAL_AMOUNT_OF_DOWNLOADS = 2;

const BAD_AMOUNT_OF_RESOLVED_SENSORS = 14;
const OPTIMAL_AMOUNT_OF_RESOLVED_SENSORS = 7;

const TableRow = (props: Props) => {
  const {t} = useTranslation();

  const [currentSensorName, setCurrentSensorName] = useState('');
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    text: string,
  ) => {
    event.stopPropagation();

    setAnchor(event.currentTarget);
    setCurrentSensorName(text);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  // @@TODO add sort for staff

  const allResolvedMin = props.rowData.sensors
    ?.filter(sensor => !sensor.isDisabled)
    ?.reduce((acc, cur) => {
      return [...acc, cur.resolvedMin];
    }, []);
  const allResolvedMax = props.rowData.sensors
    ?.filter(sensor => !sensor.isDisabled)
    .reduce((acc, cur) => {
      return [...acc, cur.resolvedMax];
    }, []);
  const allResolvedAvg = props.rowData.sensors
    ?.filter(sensor => !sensor.isDisabled)
    .reduce((acc, cur) => {
      return acc + cur.resolvedAvg;
    }, 0);
  const min =
    allResolvedMin && allResolvedMin.length ? Math.min(...allResolvedMin) : 1;
  const max =
    allResolvedMax && allResolvedMax.length ? Math.max(...allResolvedMax) : 1;
  const avg = Math.round(allResolvedAvg / props.rowData.sensors?.length);

  const downloadsFromAll = props.rowData.downloadMetrics?.reduce((acc, cur) => {
    return acc + cur.downloads;
  }, 0);

  const downloadsResolvedBgColor = (
    downloads: number,
    amountOfWeeks: number,
  ) => {
    return downloads >= GOOD_AMOUNT_OF_DOWNLOADS * amountOfWeeks
      ? 'green'
      : downloads >= OPTIMAL_AMOUNT_OF_DOWNLOADS * amountOfWeeks
        ? 'yellow'
        : 'red';
  };

  const generateResolvedBgColor = (amount: number) =>
    amount >= BAD_AMOUNT_OF_RESOLVED_SENSORS
      ? 'red'
      : amount >= OPTIMAL_AMOUNT_OF_RESOLVED_SENSORS
        ? 'yellow'
        : 'green';

  const resolvedLineWidth = (amount: number, max: number) =>
    (100 * amount) / max;

  const allDownloads = props.rowData.downloadMetrics?.reduce((acc, cur) => {
    return acc + Number(cur.downloads);
  }, 0);

  const chopDivider = () => (
    <Box
      sx={{
        mt: 4,
      }}
    />
  );

  return (
    <Root>
      {props.type === 'summary' ? (
        <Box className={classes.container} onClick={props.onClick}>
          <TableCell isTiny>
            <ArrowIcon
              isTransparent={
                !props.rowData.sensors.length &&
                props.rowData.connectedUsers.length < 2
              }
              isExpanded={props.isExpanded || false}
            />
          </TableCell>
          <TableCell>{props.rowData.company.name}</TableCell>
          <TableCell>
            {!props.isExpanded ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {(props.rowData.connectedUsers[0] &&
                  props.rowData.connectedUsers[0].profile.name) ||
                  ''}{' '}
                {props.rowData.connectedUsers.length >= 2 && (
                  <Box
                    className={classes.usersAmount}
                    component={'span'}
                  >{`+${props.rowData.connectedUsers.length - 1}`}</Box>
                )}
              </Box>
            ) : (
              <Box
                className={clsx(
                  props.isExpanded &&
                    props.rowData.connectedUsers.length &&
                    classes.usersWrapper,
                )}
              >
                {props.rowData.connectedUsers.map((user, index) => (
                  <Box key={index}>{user.profile.name || ''}</Box>
                ))}
              </Box>
            )}
          </TableCell>
          {props.rowData.sensors.length ? (
            <>
              <TableCell isWide>
                <Box className={classes.sensorsAmount}>
                  {' '}
                  {props.rowData.sensors.length}
                </Box>

                {chopDivider()}

                {props.isExpanded &&
                  props.rowData.sensors.map(sensor => (
                    <>
                      <Box
                        key={sensor.id}
                        className={classes.sensorNameContainer}
                      >
                        <Box
                          className={classes.sensorName}
                          onMouseEnter={e =>
                            handlePopoverOpen(e, sensorName(sensor.name))
                          }
                          onMouseLeave={handlePopoverClose}
                        >
                          {sensorName(sensor.name)}
                        </Box>
                        {sensor.hasExceptions && (
                          <Box
                            className={classes.exceptionsIconWrapper}
                            onMouseEnter={e =>
                              handlePopoverOpen(
                                e,
                                t('В сенсор добавлены исключения'),
                              )
                            }
                            onMouseLeave={handlePopoverClose}
                          >
                            <FilterListIcon
                              className={classes.exceptionsIcon}
                            />
                          </Box>
                        )}
                      </Box>
                    </>
                  ))}

                <BasePopup id={id} open={open} anchor={anchor}>
                  <PopupBody>{currentSensorName}</PopupBody>
                </BasePopup>
              </TableCell>
              <TableCell isSlim>
                {props.rowData.sensors.reduce((acc, cur) => {
                  acc += cur.found;
                  return acc;
                }, 0)}

                {chopDivider()}
                {props.isExpanded &&
                  props.rowData.sensors.map(sensor => (
                    <Box key={sensor.id}>
                      {!sensor.isDisabled ? sensor.found : ''}
                    </Box>
                  ))}
              </TableCell>
              <TableCell isSlim>
                {props.rowData.sensors.reduce((acc, cur) => {
                  acc += cur.fixed;
                  return acc;
                }, 0)}
                {chopDivider()}
                {props.isExpanded &&
                  props.rowData.sensors.map(sensor => (
                    <Box key={sensor.id}>
                      {!sensor.isDisabled ? sensor.fixed : ''}
                    </Box>
                  ))}
              </TableCell>
              <TableCell isSlim>
                {(() => {
                  const found = props.rowData.sensors.reduce((acc, cur) => acc + cur.found, 0);
                  const fixed = props.rowData.sensors.reduce((acc, cur) => acc + cur.fixed, 0);
                  return found > 0 ? `${Math.round((fixed / found) * 100)}%` : '0%';
                })()}
                {chopDivider()}
                {props.isExpanded &&
                  props.rowData.sensors.map(sensor => (
                    <Box key={sensor.id}>
                      {!sensor.isDisabled
                        ? `${sensor.found > 0
                            ? Math.round((sensor.fixed / sensor.found) * 100)
                            : 0}%`
                        : ''}
                    </Box>
                  ))}
              </TableCell>
              <TableCell>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Box className={classes.resolvedAmount}> {avg}</Box>

                  <ResolvedLine
                    value={avg}
                    bgColor={generateResolvedBgColor(avg)}
                  />
                </Box>
                {chopDivider()}
                {props.isExpanded &&
                  props.rowData.sensors.map((sensor, index) => (
                    <Box
                      key={index}
                      sx={{display: 'flex', alignItems: 'center'}}
                    >
                      <Box className={classes.resolvedAmount}>
                        {' '}
                        {!sensor.isDisabled ? sensor.resolvedAvg : ''}
                      </Box>
                      {!sensor.isDisabled && (
                        <ResolvedLine
                          value={resolvedLineWidth(sensor.resolvedAvg, max)}
                          bgColor={generateResolvedBgColor(sensor.resolvedAvg)}
                        />
                      )}
                    </Box>
                  ))}
              </TableCell>
              <TableCell>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Box className={classes.resolvedAmount}> {min}</Box>

                  <ResolvedLine
                    value={min}
                    bgColor={generateResolvedBgColor(min)}
                  />
                </Box>
                {chopDivider()}
                {props.isExpanded &&
                  props.rowData.sensors.map((sensor, index) => (
                    <Box
                      key={index}
                      sx={{display: 'flex', alignItems: 'center'}}
                    >
                      <Box className={classes.resolvedAmount}>
                        {' '}
                        {sensor.resolvedMin}
                      </Box>

                      {!sensor.isDisabled && (
                        <ResolvedLine
                          value={resolvedLineWidth(sensor.resolvedMin, max)}
                          bgColor={generateResolvedBgColor(sensor.resolvedMin)}
                        />
                      )}
                    </Box>
                  ))}
              </TableCell>
              <TableCell>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Box className={classes.resolvedAmount}> {max}</Box>

                  <ResolvedLine
                    value={max}
                    bgColor={generateResolvedBgColor(max)}
                  />
                </Box>
                {chopDivider()}
                {props.isExpanded &&
                  props.rowData.sensors.map((sensor, index) => (
                    <Box
                      key={index}
                      sx={{display: 'flex', alignItems: 'center'}}
                    >
                      <Box className={classes.resolvedAmount}>
                        {' '}
                        {sensor.resolvedMax}
                      </Box>

                      {!sensor.isDisabled && (
                        <ResolvedLine
                          value={resolvedLineWidth(sensor.resolvedMax, max)}
                          bgColor={generateResolvedBgColor(sensor.resolvedMax)}
                        />
                      )}
                    </Box>
                  ))}
              </TableCell>
            </>
          ) : (
            <TableCell isWide>
              <Box className={classes.sensorsAmount}>
                {' '}
                {props.rowData.sensors.length}
              </Box>
            </TableCell>
          )}
        </Box>
      ) : (
        <Box className={classes.container} onClick={props.onClick}>
          <TableCell isTiny>
            <ArrowIcon isExpanded={props.isExpanded || false} />
          </TableCell>
          <TableCell isStaffTable>
            {props.rowData.connectedUser.profile ? (
              props.rowData.connectedUser.profile.name
            ) : (
              <Trans>Главный аккаунт</Trans>
            )}
          </TableCell>
          <TableCell isStaffTable>
            <Box className={classes.usersAmount}>
              {t('plurals.companies', {
                count: props.rowData.downloadMetrics.length,
              })}
            </Box>

            {chopDivider()}

            {props.isExpanded &&
              props.rowData.downloadMetrics.map((metric, index) => (
                <Box key={index}>{metric.company.name}</Box>
              ))}
          </TableCell>
          <TableCell isStaffTable>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box className={classes.resolvedAmountStaff}>{allDownloads}</Box>
              <ResolvedLine
                value={allDownloads}
                bgColor={downloadsResolvedBgColor(
                  allDownloads,
                  props.amountOfWeeks || 1,
                )}
              />
            </Box>

            {chopDivider()}

            {props.isExpanded &&
              props.rowData.downloadMetrics.map((metric, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box className={classes.resolvedAmountStaff}>
                    {metric.downloads}
                  </Box>
                  <ResolvedLine
                    value={resolvedLineWidth(
                      metric.downloads,
                      downloadsFromAll,
                    )}
                    bgColor={downloadsResolvedBgColor(
                      metric.downloads,
                      props.amountOfWeeks || 1,
                    )}
                  />
                </Box>
              ))}
          </TableCell>
        </Box>
      )}
    </Root>
  );
};

export default TableRow;
