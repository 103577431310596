/** @format */

import {styled} from '@mui/styles';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const StyledButtonGroup = styled(Box)(() => ({
  width: '100%',
}));

export const useStyles = makeStyles(theme => ({
  button: {
    ['&.MuiButtonBase-root']: {
      fontSize: '14px',
      borderRadius: 0,
      height: '48px',
      minWidth: 'max-content',
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      background: '#F1F1F1',
    },
  },
  selected: {
    ['&.MuiButtonBase-root']: {
      color: '#F1F1F1',
      background: '#547AE0',
    },
  },
  disabledColor: {
    ['&.MuiButtonBase-root']: {
      color: '#8B8B8B',
    },
  },
  disabledBackground: {
    ['&.MuiButtonBase-root']: {
      background: '#8B8B8B',
    },
  },
}));
