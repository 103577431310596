/** @format */

import {gql} from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($id: ID!) {
    allSensors {
      name
    }
    currentUser {
      company(id: $id) {
        name
        tokens {
          id
          isValid
          lastError
          provider
        }
        uuid
        sensorGroups {
          red
          yellow
          gray
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_SENSOR_GROUPS = gql`
  mutation UpdateCompanySensorGroups(
    $id: ID!
    $sensorGroups: AccountsCompanySensorGroupsInput!
  ) {
    updateCompanySensorGroups(id: $id, sensorGroups: $sensorGroups)
  }
`;
