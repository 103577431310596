/** @format */

import {InputAdornment} from '@mui/material';

import {EmailOutlined, LocalPhoneOutlined, Telegram} from '@mui/icons-material';

import {ContactType} from './model';

const CustomInputAdornment = ({type}: {type: ContactType}) => {
  const getImage = () => {
    switch (type) {
      case 'email':
        return <EmailOutlined sx={{color: '#8B8B8B', fontSize: '16px'}} />;
      case 'telegram':
        return <Telegram sx={{color: '#8B8B8B', fontSize: '16px'}} />;
      case 'phone':
        return <LocalPhoneOutlined sx={{color: '#8B8B8B', fontSize: '16px'}} />;
      default:
        return '';
    }
  };

  return <InputAdornment position='start'>{getImage()}</InputAdornment>;
};

export {CustomInputAdornment};
