/** @format */

import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';

import theme from 'theme';

import {classes, COLORS} from '../constants';

type PeriodType = 'repeatable' | 'flight';

export const StyledBox = styled(Box)(() => ({
  width: '100%',
  height: '320px',
  borderRadius: '8px',
  background: COLORS.background,
  padding: `${theme.spacing(8)} 0 ${theme.spacing(16)} ${theme.spacing(8)}`,
  position: 'relative',
  overflow: 'hidden',
  [`& .${classes.daysWrapper}`]: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    borderLeft: `1px solid ${COLORS.boxBorder}`,
    borderBottom: `1px solid ${COLORS.boxBorder}`,
  },
  [`& .${classes.monthsWrapper}`]: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
}));

export const PeriodHeading = styled(Box)(() => ({
  color: COLORS.text.primary,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '0.28px',
  marginTop: theme.spacing(4),
}));

export const PeriodDescription = styled(Box)(() => ({
  color: COLORS.text.secondary,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  marginTop: theme.spacing(1),
}));

export const DayBox = styled(Box)<{width: number}>(({width}) => ({
  width: `${width}%`,
  height: '100%',
  boxSizing: 'border-box',
  borderLeft: `1px solid ${COLORS.dayBorder}`,
  '&:last-of-type': {
    borderRight: `1px solid ${COLORS.dayBorder}`,
  },
}));

export const PeriodStartDay = styled(Box)<{left: number}>(({left}) => ({
  position: 'absolute',
  top: 0,
  left: `${left}%`,
  color: COLORS.period.repeatable,
  fontWeight: 'bold',
  transform: 'translate(-42%, 0)',
  fontSize: '14px',
}));

export const StartPeriodLine = styled(Box)<{type: PeriodType; left: number}>(
  ({type, left}) => ({
    position: 'absolute',
    top: 0,
    left: `${left}%`,
    width: '2px',
    height: '100%',
    backgroundColor:
      type === 'repeatable' ? COLORS.period.repeatable : COLORS.period.flight,
    transform: 'translate(-50%, 0)',
  }),
);

export const PeriodBox = styled(Box)<{
  left: number;
  width: number;
  type: PeriodType;
}>(({left, width, type}) => ({
  position: 'absolute',
  top: 0,
  left: `${left}%`,
  width: `${width}%`,
  transform: 'translateX(-1px)',
  '&::after': {
    content: '""',
    position: 'absolute',
    right: '-1px',
    top: 0,
    bottom: 0,
    width: '1px',
    background: 'inherit',
  },
  display: 'flex',
  flexDirection: 'column',
  zIndex: 2,
  height: '100%',
  background:
    type === 'repeatable'
      ? 'linear-gradient(0deg, rgba(84, 122, 224, 0.20) 0%, rgba(84, 122, 224, 0.20) 100%)'
      : 'linear-gradient(0deg, rgba(116, 159, 35, 0.20) 0%, rgba(116, 159, 35, 0.20) 100%)',
}));

export const MonthBox = styled(Box)<{amount: number}>(({amount}) => ({
  width: `${amount}%`,
  display: 'flex',
  borderLeft: `1px solid ${COLORS.boxBorder}`,
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(4),
}));
