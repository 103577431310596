/** @format */

import {Dispatch} from 'react';

import {validate} from 'email-validator';

import {useTranslation} from 'react-i18next';

import {ActionProps} from './stateReducer';
import {ContactType} from './model';

interface ReturnValidation {
  validateEmail: (
    contact: string,
    type: ContactType,
    dispatch: Dispatch<ActionProps>,
  ) => boolean;
}

const useValidation = (): ReturnValidation => {
  const {t} = useTranslation();

  const validateEmail = (
    contact: string,
    type: ContactType,
    dispatch: Dispatch<ActionProps>,
  ) => {
    if (contact.trim() === '') {
      dispatch({
        action: 'contactErrors',
        payload: t('Обязательное поле'),
      });
      return false;
    }

    if (type === 'email') {
      if (!validate(contact)) {
        dispatch({
          action: 'contactErrors',
          payload: t('Введен некорректный email-адрес'),
        });
        return false;
      }
    }

    return true;
  };

  return {validateEmail};
};

export {useValidation};
