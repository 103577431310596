/** @format */

import {ContactType, StateProps} from './model';

export interface ActionProps {
  action: keyof StateProps;
  payload: unknown;
}

const stateReducer = (
  state: StateProps,
  {action, payload}: ActionProps,
): StateProps => {
  switch (action) {
    case 'type':
      return {
        ...state,
        type: payload as ContactType,
      };
    case 'contact':
      return {
        ...state,
        contact: payload as string,
      };
    case 'comment':
      return {
        ...state,
        comment: payload as string,
      };
    case 'contactErrors':
      return {
        ...state,
        contactErrors: payload as string | null,
      };
    default:
      return state;
  }
};

export {stateReducer};
